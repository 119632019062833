import React from 'react';

// ant
import { Modal, Descriptions, Button } from 'antd';

// components
import ButtonCitaPDF from '../common/ButtonCitaPDF';

const ShowCitaModal = (props) => {
  const { cita } = props.cita;

  const handleCancel = () => {
    props.setVisible(false);
  };

  return (
    <Modal
      title={`Cita ${cita.Tipo !== 'Normal' ? cita.Tipo : ''}`}
      visible={props.visible}
      closable={false}
      footer={[
        <ButtonCitaPDF cita={cita} />,
        <Button
          type="primary"
          onClick={() => props.editCita(cita)}
          disabled={cita.Tipo === 'Bloqueo'}
        >
          Editar Estado
        </Button>,
        <Button
          danger
          onClick={() => props.borrarCita(cita)}
          disabled={cita.Tipo === 'Bloqueo'}
        >
          Borrar
        </Button>,
        <Button danger onClick={handleCancel}>
          Cerrar
        </Button>,
      ]}
    >
      <Descriptions
        bordered
        size="small"
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="Paciente">
          {cita.estudio
            ? `${cita.Nombre} ${cita.Apellido_Paterno} ${cita.Apellido_Materno}`
            : 'Bloqueo'}
        </Descriptions.Item>
        <Descriptions.Item label="Teléfono del paciente">
          {cita.Telefono || 'Bloqueo'}
        </Descriptions.Item>
        <Descriptions.Item label="Estudio">
          {cita?.estudio?.Nombre || 'Bloqueo'}
        </Descriptions.Item>
        <Descriptions.Item label="Informacion de estudio">
          {cita?.estudio?.Informacion || 'Bloqueo'}
        </Descriptions.Item>
        <Descriptions.Item label="Estado de cita">
          {cita.Estado_Cita}
        </Descriptions.Item>
        <Descriptions.Item label="Estado de cita">
          {cita.Estado_Cita}
        </Descriptions.Item>
        <Descriptions.Item label="Hora Inicio">
          {cita.Hora_Inicio}
        </Descriptions.Item>
        <Descriptions.Item label="Hora Fin">{cita.Hora_Fin}</Descriptions.Item>
        <Descriptions.Item label="Numero de cita">
          {cita.Id.toString().padStart(3, '0')}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

ShowCitaModal.defaultProps = {};

ShowCitaModal.propTypes = {};

export default ShowCitaModal;
