import React, { useState } from 'react';

// redux
import { connect } from 'react-redux';

// router
import { useHistory } from 'react-router-dom';

// ant
import { Layout, Menu } from 'antd';
import {
  CalendarOutlined,
  BookOutlined,
  UserOutlined,
  UnorderedListOutlined,
  BlockOutlined,
} from '@ant-design/icons';

// utils
import { URL_LOGO_VERTICAL } from '../../utils/Constantes';

const { Sider } = Layout;
// const { SubMenu } = Menu;

const MenuSider = props => {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  // console.log(props.rol);

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  const gotoCitas = () => {
    history.push('/');
  };

  const gotoDepartamentos = () => {
    history.push('/departamentos');
  };

  const gotoUsuarios = () => {
    history.push('/usuarios');
  };

  const gotoEstudios = () => {
    history.push('/estudios');
  };

  const gotoCupones = () => {
    history.push('/cupones');
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      style={{ background: '#3DBFE7' }}
    >
      <div className="logo">
        <img src={URL_LOGO_VERTICAL} alt="..." style={{ maxHeight: '44px' }} />
      </div>
      <Menu
        theme="light"
        defaultSelectedKeys={['citas']}
        mode="inline"
        style={{ background: '#3DBFE7' }}
      >
        {/* Citas */}
        <Menu.Item
          key="citas"
          icon={<CalendarOutlined />}
          style={{ color: '#4a3088' }}
          onClick={gotoCitas}
        >
          Citas
        </Menu.Item>

        {/* =============== Rutas Admin =============== */}
        {/* Estudios */}
        {props.rol === 'ADMIN' && (
          <Menu.Item
            key="estudios"
            icon={<UnorderedListOutlined />}
            style={{ color: '#4a3088' }}
            onClick={gotoEstudios}
          >
            Estudios
          </Menu.Item>
        )}

        {/* Departamentos */}
        {props.rol === 'ADMIN' && (
          <Menu.Item
            key="departamentos"
            icon={<BookOutlined />}
            style={{ color: '#4a3088' }}
            onClick={gotoDepartamentos}
          >
            Departamentos
          </Menu.Item>
        )}

        {/* Cupones */}
        {props.rol === 'ADMIN' && (
          <Menu.Item
            key="cupones"
            icon={<BlockOutlined />}
            style={{ color: '#4a3088' }}
            onClick={gotoCupones}
          >
            Cupones
          </Menu.Item>
        )}

        {/* Usuarios */}
        {props.rol === 'ADMIN' && (
          <Menu.Item
            key="usuarios"
            icon={<UserOutlined />}
            style={{ color: '#4a3088' }}
            onClick={gotoUsuarios}
          >
            Usuarios
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  );
};
const mapStateToProps = state => {
  return {
    rol: state.auth.usuario.role.Nombre,
  };
};

// const mapDispatchToProps = (dispatch) => {};
export default connect(mapStateToProps)(MenuSider);
