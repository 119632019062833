import React from 'react';
// ant
import { Button, Card, Table, Input, Space } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

//  components
import ButtonTooltip from './../common/ButtonTooltip';
import ButtonPopconfirm from './../common/ButtonPopconfirm';

// lodash
import get from 'lodash.get';
import isequal from 'lodash.isequal';

// moment
import moment from 'moment';

const { Column } = Table;

class CuponesTable extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      get(record, dataIndex)
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      isequal(this.state.searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    return (
      <Card
        title="Cupones"
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={this.props.addCupones}
          >
            Agregar
          </Button>
        }
      >
        <Table
          dataSource={this.props.cupones}
          size="small"
          width="auto"
          height="auto"
          pagination={{ pageSize: 20 }}
          scroll={{ x: 800 }}
          rowKey="Id"
        >
          {/* <Column title="Id" dataIndex="Id" /> */}
          <Column
            title="Código"
            dataIndex="Codigo"
            {...this.getColumnSearchProps('Codigo')}
          />
          <Column
            title="Departamento"
            dataIndex={['departamento', 'Nombre']}
            {...this.getColumnSearchProps(['departamento', 'Nombre'])}
          />
          <Column
            title="Estado"
            dataIndex="Estado"
            {...this.getColumnSearchProps('Estado')}
          />
          <Column
            title="Inicio Vigencia"
            render={(text, record) => (
              <p>{moment(record.Vigencia_Inicio).format('L')}</p>
            )}
            sorter={(a, b) =>
              new Date(a.Vigencia_Inicio).getTime() <
              new Date(b.Vigencia_Inicio).getTime()
            }
          />
          <Column
            title="Fin Vigencia"
            render={(text, record) => (
              <p>{moment(record.Vigencia_Fin).format('L')}</p>
            )}
            sorter={(a, b) =>
              new Date(a.Vigencia_Fin).getTime() <
              new Date(b.Vigencia_Fin).getTime()
            }
          />
          {/* <Column title="Notas" dataIndex="Notas" /> */}
          <Column
            title="Acciones"
            width={150}
            fixed="right"
            render={(text, record) => (
              <>
                <ButtonTooltip
                  icon={EditOutlined}
                  title="Editar"
                  onClick={() => this.props.editCupon(record)}
                />
                <ButtonPopconfirm
                  icon={DeleteOutlined}
                  title="¿Desea eliminar el cupón seleccionado?"
                  tooltip="Eliminar"
                  onConfirm={() => this.props.deleteCupon(record.Id)}
                />
              </>
            )}
          />
        </Table>
      </Card>
    );
  }
}

CuponesTable.defaultProps = {};

CuponesTable.propTypes = {};

export default CuponesTable;
