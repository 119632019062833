import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const AdminRoute = ({ component: Component, ...props }) => {
  const { authenticated, usuario } = props.auth;
  const rol = usuario.role.Nombre;

  return (
    <Route
      {...props}
      render={(props) =>
        authenticated && usuario && rol === 'ADMIN' ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminRoute);
