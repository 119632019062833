import axiosClient from '../../config/axiosClient';
import {
  GET_USUARIOS,
  POST_USUARIO,
  PUT_USUARIO,
  DELETE_USUARIO,
  PATCH_USUARIO,
  SET_USUARIO,
} from './../types/index';

/**
 * Obtener a todos los usuarios
 */
export const getUsuarios = () => async (dispatch) => {
  try {
    const response = await axiosClient.get('/usuarios');
    // console.log(response);
    if (response.data.ok) {
      dispatch({
        type: GET_USUARIOS,
        payload: { usuarios: response.data.usuarios },
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Crear un nuevo usuario
 * @param usuario {Object}
 */
export const createUsuario = (usuario) => async (dispatch) => {
  try {
    const response = await axiosClient.post('/usuarios', usuario);
    console.log(response);
    if (response.data.ok) {
      dispatch({
        type: POST_USUARIO,
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Actualizar un usuario sin password
 * @param usuario {Object}
 */
export const updateUsuario = (usuario) => async (dispatch) => {
  try {
    const response = await axiosClient.put('/usuarios', usuario);
    // console.log(response);
    if (response.data.ok) {
      dispatch({
        type: PUT_USUARIO,
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Actualizar password a un usuario
 * @param password {Object}
 */
export const updatePassword = (password) => async (dispatch) => {
  try {
    const response = await axiosClient.patch('/usuarios', password);
    // console.log(response);
    if (response.data.ok) {
      dispatch({
        type: PATCH_USUARIO,
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Borrar un usuario
 * @param usuarioId {number}
 */
export const deleteUsuario = (usuarioId) => async (dispatch) => {
  try {
    const response = await axiosClient.delete(`/usuarios/${usuarioId}`);
    if (response.data.ok) {
      dispatch({
        type: DELETE_USUARIO,
      });
    }
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Establecer un usuario para editar
 * @param usuario {Object}
 */
export const setUsuario = (usuario) => async (dispatch) => {
  dispatch({
    type: SET_USUARIO,
    payload: { usuario },
  });
};
