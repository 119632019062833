import { combineReducers } from 'redux';
import citasReducer from './citasReducer';
import estudiosReducer from './estudiosReducer';
import departamentosReducer from './departamentosReducer';
import authReducer from './authReducer';
import usuariosReducer from './usuariosReducer';
import rolesReducer from './rolesReducer';
import agendaReducer from './agendaReducer';
import cuponesReducer from './cuponesReducer';

export default combineReducers({
  citas: citasReducer,
  estudios: estudiosReducer,
  departamentos: departamentosReducer,
  auth: authReducer,
  usuarios: usuariosReducer,
  roles: rolesReducer,
  agenda: agendaReducer,
  cupones: cuponesReducer,
});
