import React, { useState, useEffect } from 'react';

// redux
import { connect } from 'react-redux';
import { getDepartamentos } from './../../redux/actions/departamentosAction';
import {
  getEstudios,
  deleteEstudio,
  setEstudio,
} from './../../redux/actions/estudiosAction';

// ant
import { Spin, Select, Row, Col } from 'antd';

// components
import BreadcrumbList from '../common/BreadcrumbList';
import EstudiosTable from './EstudiosTable';

const EstudiosIndex = (props) => {
  const [loading, setLoading] = useState(false);
  // departamento seleccionado
  const [departamento, setDepartamento] = useState(props.depto);
  // TODO filtrar estudios
  // const [estudios, setEstudios] = useState(props.estudios);

  useEffect(() => {
    getDeptos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDeptos = async () => {
    setLoading(true);
    await props.onGetDepartamentos();
    if (departamento !== '') {
      await props.onGetEstudios(departamento);
    }
    setLoading(false);
  };

  const onChange = async (e) => {
    setLoading(true);
    await props.onGetEstudios(e);
    setDepartamento(e);
    setLoading(false);
    // console.log(e);
  };

  const addEstudio = () => {
    // console.log(departamento);
    props.onSetEstudio(null, departamento);
    props.history.push('/estudios/agregar');
  };

  const editEstudio = (estudio) => {
    // console.log(departamento);
    props.onSetEstudio(estudio, departamento);
    props.history.push('/estudios/editar');
  };

  const deleteEstudio = async (estudioId) => {
    setLoading(true);
    await props.onDeleteEstudio(estudioId);
    await props.onGetEstudios(departamento);
    setLoading(false);
  };

  const showOptionsDeptos = (options) => {
    // console.log(options);
    // const optionsFiltered = options.filter(
    //   (option) => option.Estado === 'Activo',
    // );
    // console.log(optionsFiltered);
    return options.map((option) => (
      <Select.Option key={option.Id} value={option.Nombre}>
        {option.Nombre}
      </Select.Option>
    ));
  };

  return (
    <>
      <BreadcrumbList items={['Estudios', 'Inicio']} />
      {/* Select para departamentos */}
      <Row justify="start" style={{ marginBottom: '1rem' }}>
        <Col xs={24} md={8} style={{ textAlign: 'center' }}>
          <Spin spinning={loading}>
            <Select
              style={{ width: '100%' }}
              defaultValue={departamento}
              onChange={onChange}
            >
              <Select.Option value="" disabled>
                Seleccione un departamento
              </Select.Option>
              {showOptionsDeptos(props.departamentos)}
            </Select>
          </Spin>
        </Col>
      </Row>

      {/* Tabla de estudios del departamento selecionado */}
      {departamento !== '' && (
        <Row>
          <Col xs={24}>
            <Spin spinning={loading}>
              <EstudiosTable
                estudios={props.estudios}
                addEstudio={addEstudio}
                editEstudio={editEstudio}
                deleteEstudio={deleteEstudio}
              />
            </Spin>
          </Col>
        </Row>
      )}
    </>
  );
};

EstudiosIndex.defaultProps = {};

EstudiosIndex.propTypes = {};

const mapStateToProps = (state) => {
  return {
    departamentos: state.departamentos.departamentos,
    estudios: state.estudios.estudios,
    depto: state.estudios.depto,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDepartamentos: () => dispatch(getDepartamentos()),
    onGetEstudios: (deptoNombre) => dispatch(getEstudios(deptoNombre)),
    onDeleteEstudio: (estudioId) => dispatch(deleteEstudio(estudioId)),
    onSetEstudio: (estudio, depto) => dispatch(setEstudio(estudio, depto)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EstudiosIndex);
