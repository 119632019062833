import { POST_AUTH, GET_AUTH, LOGOUT } from './../types/index';

const initialState = {
  usuario: null,
  token: localStorage.getItem('citas-medicarmen'),
  authenticated: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_AUTH:
      localStorage.setItem('citas-medicarmen', action.payload.token);
      return {
        ...state,
        authenticated: true,
        token: action.payload.token,
        usuario: action.payload.usuario,
      };
    case GET_AUTH:
      return {
        ...state,
        usuario: action.payload.usuario,
        token: localStorage.getItem('citas-medicarmen'),
        authenticated: true,
      };
    case LOGOUT:
      localStorage.removeItem('citas-medicarmen');
      return {
        ...state,
        usuario: null,
        token: null,
        authenticated: false,
      };
    default:
      return state;
  }
};
