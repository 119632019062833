import React, { useState } from 'react';

// redux
import { connect } from 'react-redux';
import { updatePassword } from '../../redux/actions/usuariosAction';

// ant
import { Modal, Button, Spin, Form, Input } from 'antd';

// components
import { formItemLayoutModal } from './../../utils/FormItemLayout';
import { openNotification } from './../../utils/Notification';

const PasswordFormulario = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    props.setVisible(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const valuesForm = await form.validateFields();
      console.log(valuesForm);
      // validar que las contraseñas sean iguales
      if (props.usuario && valuesForm.Password !== valuesForm.ConfirmPassword) {
        openNotification('Password', 'Las passwords no coinciden', 'warning');
        return;
      }

      const response = await props.onUpdatePassword({
        ...valuesForm,
        Id: props.usuario.Id,
      });

      if (!response) {
        openNotification(
          'Error',
          'Ocurrio un error, intente mas tarde',
          'error',
        );
      } else if (!response.ok) {
        openNotification('Error', response.message, 'error');
      } else if (response.ok) {
        openNotification('OK', `Password editada correctamente`, 'success', 3);
        handleCancel();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={props.visible}
      title={`Editar Password a ${props.usuario.Nombre}`}
      closable={false}
      footer={[
        <Button type="primary" onClick={handleSubmit} loading={loading}>
          Guardar
        </Button>,
        <Button danger onClick={handleCancel} loading={loading}>
          Cancelar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form
          {...formItemLayoutModal}
          form={form}
          name="PasswordForm"
          scrollToFirstError
          labelAlign="left"
        >
          {/* Password */}
          <Form.Item
            name="Password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'La password es requerida y con al menos 6 caracteres',
                min: 6,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          {/*Confirm Password */}
          <Form.Item
            name="ConfirmPassword"
            label="Confirmar Password"
            rules={[
              {
                required: true,
                message:
                  'La confirmacion es requerida y con al menos 6 caracteres',
                min: 6,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

PasswordFormulario.defaultProps = {};

PasswordFormulario.propTypes = {};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdatePassword: (password) => dispatch(updatePassword(password)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PasswordFormulario);
