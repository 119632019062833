import axiosClient from '../../config/axiosClient';
import { GET_AGENDA, POST_AGENDA, PATCH_AGENDA } from './../types/index';

/**
 * Obtener todos lo departamentos
 * @param deptoId {String}
 */
export const getAgenda = (deptoId) => async (dispatch) => {
  try {
    const response = await axiosClient.get(`/agendas/${deptoId}`);
    if (response.data.ok) {
      dispatch({
        type: GET_AGENDA,
        payload: {
          agenda: response.data.agenda,
          detalles: response.data.detalles,
        },
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Generar agenda y detalles
 * @param deptoId {String}
 * @param agenda {Object}
 * @param detalles {Array}
 */
export const postAgenda = (deptoId, agenda, detalles) => async (dispatch) => {
  try {
    const response = await axiosClient.post(`/agendas/${deptoId}`, {
      agenda: agenda,
      detalles: detalles,
    });
    if (response.data.ok) {
      dispatch({
        type: POST_AGENDA,
        payload: {
          agenda: response.data.agenda,
          detalles: response.data.detalles,
        },
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Actualiza el estado de un detalle
 * @param detalle {Object}
 */
export const patchAgenda = (detalle) => async (dispatch) => {
  try {
    const response = await axiosClient.patch('/agendas', detalle);
    if (response.data.ok) {
      dispatch({
        type: PATCH_AGENDA,
        payload: {
          detalles: response.data.detalles,
        },
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
