import { notification } from 'antd';

// types: success, info, warning, error

export const openNotification = (
  message,
  description,
  type = null,
  duration = 0,
) =>
  type
    ? notification[type]({ message, description, duration })
    : notification.open({ message, description, duration });
