import React, { useState, useEffect } from 'react';

// redux
import { connect } from 'react-redux';
import {
  getUsuarios,
  deleteUsuario,
  setUsuario,
} from '../../redux/actions/usuariosAction';

// ant
import { Spin } from 'antd';

// components
import BreadcrumbList from './../common/BreadcrumbList';
import UsuariosTable from './UsuariosTable';
import PasswordFormulario from './PasswordFormulario';

const UsuariosIndex = (props) => {
  const [loading, setLoading] = useState(false);
  const [openPasswordForm, setOpenPasswordForm] = useState(false);
  const [usuarioEdit, setUsuarioEdit] = useState(null);

  useEffect(() => {
    getUsuarios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsuarios = async () => {
    setLoading(true);
    await props.onGetUsuarios();
    setLoading(false);
  };

  const addUsuario = () => {
    props.onSetUsuario(null);
    props.history.push('/usuarios/agregar');
    // setOpenUsuarioForm(true);
  };

  const editUsuario = (usuario) => {
    props.onSetUsuario(usuario);
    props.history.push('/usuarios/editar');
    // setOpenUsuarioForm(true);
  };

  const editPassword = (usuario) => {
    setUsuarioEdit(usuario);
    setOpenPasswordForm(true);
  };

  const deleteUsuario = async (usuarioId) => {
    setLoading(true);
    await props.onDeleteUsuario(usuarioId);
    getUsuarios();
    setLoading(false);
  };

  return (
    <>
      <BreadcrumbList items={['Usuarios', 'Inicio']} />

      {/* Tabla de usuarios */}
      <Spin spinning={loading}>
        <UsuariosTable
          usuarios={props.usuarios}
          addUsuario={addUsuario}
          editUsuario={editUsuario}
          deleteUsuario={deleteUsuario}
          editPassword={editPassword}
        />
      </Spin>

      {/* Formulario para cambias password */}
      {openPasswordForm && (
        <PasswordFormulario
          usuario={usuarioEdit}
          visible={openPasswordForm}
          setVisible={setOpenPasswordForm}
        />
      )}
    </>
  );
};

UsuariosIndex.defaultProps = {};

UsuariosIndex.propTypes = {};

const mapStateToProps = (state) => {
  return {
    usuarios: state.usuarios.usuarios,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUsuarios: () => dispatch(getUsuarios()),
    onDeleteUsuario: (usuarioId) => dispatch(deleteUsuario(usuarioId)),
    onSetUsuario: (usuario) => dispatch(setUsuario(usuario)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UsuariosIndex);
