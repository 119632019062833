import { GET_ESTUDIOS, SET_ESTUDIO } from './../types/index';

const initialState = {
  estudios: [],
  deptoId: 0,
  estudioEdit: null,
  depto: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ESTUDIOS:
      return {
        ...state,
        estudios: action.payload.estudios,
        deptoId: action.payload.deptoId,
      };
    case SET_ESTUDIO:
      console.log(action.payload);
      return {
        ...state,
        estudioEdit: action.payload.estudio,
        depto: action.payload.depto,
      };
    default:
      return state;
  }
};
