import React from 'react';
import PropTypes from 'prop-types';
import { Popconfirm, Button, Tooltip } from 'antd';

const ButtonPopconfirm = ({ icon: Icon, ...props }) => {
  return (
    <Tooltip title={props.tooltip}>
      <Popconfirm
        title={props.title}
        onConfirm={props.onConfirm}
        okText={props.okText}
        cancelText={props.cancelText}
      >
        <Button
          type="link"
          icon={<Icon style={{ color: '#666', fontSize: '20px' }} />}
        />
      </Popconfirm>
    </Tooltip>
  );
};

ButtonPopconfirm.defaultProps = {
  okText: 'Si',
  cancelText: 'No',
};

ButtonPopconfirm.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
};

export default ButtonPopconfirm;
