import axiosClient from './../../config/axiosClient';
import tokenAuth from '../../config/tokenAuth';
import { POST_AUTH, GET_AUTH, LOGOUT } from './../types/index';

/**
 * Login
 * @param user {object} //email y password
 */
export const login = (user) => async (dispatch) => {
  try {
    const response = await axiosClient.post('/auth', user);
    // console.log(response.data);
    if (response.data.ok) {
      tokenAuth(response.data.token);
      dispatch({
        type: POST_AUTH,
        payload: {
          token: response.data.token,
          usuario: response.data.usuario,
        },
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Obtener usuario si el token aun es valido
 */
export const getAuthenticatedUser = () => async (dispatch) => {
  const token = localStorage.getItem('citas-medicarmen');
  tokenAuth(token);
  try {
    const response = await axiosClient.get('/auth');
    // console.log(response.data);
    if (response.data.ok) {
      dispatch({
        type: GET_AUTH,
        payload: { usuario: response.data.usuario },
      });
    }
    return response.data;
    // console.log(response);
  } catch (error) {
    console.log(error);
  }
};

/**
 * Cerrar sesion
 */
export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
