import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { Button, Col, Row } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
};

class Toolbar extends React.Component {
  render() {
    let {
      localizer: { messages },
      label,
    } = this.props;

    return (
      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={24} md={8} style={{ textAlign: 'center' }}>
          <Button
            type="link"
            onClick={this.navigate.bind(null, navigate.PREVIOUS)}
            icon={<LeftOutlined />}
          />
          <Button onClick={this.navigate.bind(null, navigate.TODAY)}>
            Hoy
          </Button>
          <Button
            type="link"
            onClick={this.navigate.bind(null, navigate.NEXT)}
            icon={<RightOutlined />}
          />
        </Col>

        <Col xs={24} md={8} style={{ textAlign: 'center' }}>
          {label}
        </Col>

        <Col xs={24} md={8} style={{ textAlign: 'center' }}>
          <span className="rbc-btn-group">{this.viewNamesGroup(messages)}</span>
        </Col>
      </Row>
    );
  }

  navigate = (action) => {
    this.props.onNavigate(action);
  };

  view = (view) => {
    this.props.onView(view);
  };

  viewNamesGroup(messages) {
    let viewNames = this.props.views;
    const view = this.props.view;

    if (viewNames.length > 1) {
      return viewNames.map((name) => (
        <Button
          key={name}
          className={clsx({ 'rbc-active': view === name })}
          onClick={this.view.bind(null, name)}
        >
          {messages[name]}
        </Button>
      ));
    }
  }
}

Toolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
};

export default Toolbar;
