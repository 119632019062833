import { GET_CUPONES, SET_CUPON } from './../types/index';

const initialState = {
  cupones: [],
  cuponeEdit: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CUPONES:
      return { ...state, cupones: action.payload.cupones };
    case SET_CUPON:
      return { ...state, cuponeEdit: action.payload.cupon };
    default:
      return state;
  }
};
