import React from 'react';
import { connect } from 'react-redux';

import { Layout } from 'antd';

const Footer = (props) => {
  return (
    <Layout.Footer style={{ textAlign: 'center' }}>
      Citas Admin ©2020 Created by Allsoft
    </Layout.Footer>
  );
};

Footer.defaultProps = {};

Footer.propTypes = {};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
