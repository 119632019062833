import { GET_USUARIOS, SET_USUARIO } from './../types/index';

const initialState = {
  usuarios: [],
  usuarioEdit: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USUARIOS:
      return { ...state, usuarios: action.payload.usuarios };
    case SET_USUARIO:
      return { ...state, usuarioEdit: action.payload.usuario };

    default:
      return state;
  }
};
