import React from 'react';

import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import LoginIndex from '../components/login/LoginIndex';
import DashboardRoutes from './DashboardRoutes';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

const AppRouter = (props) => {
  return (
    <Router>
      <Switch>
        <PublicRoute exact path="/login" component={LoginIndex} />

        <PrivateRoute path="/" component={DashboardRoutes} />

        <Redirect to="/login" />
      </Switch>
    </Router>
  );
};

AppRouter.defaultProps = {};

AppRouter.propTypes = {};

export default AppRouter;
