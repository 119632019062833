// ant
import { Button, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { deleteCita } from './../../redux/actions/citasAction';

const CitaBorrar = (props) => {
  const { cita } = props;

  const handleCancel = () => {
    props.setVisible(false);
  };

  console.log(cita);
  if (!cita) {
    return null;
  }

  return (
    <Modal
      title={`Cita ${cita.Tipo !== 'Normal' ? cita.Tipo : ''}`}
      visible={props.visible}
      closable={false}
      footer={[
        <Button
          type="primary"
          onClick={() => {
            props.onDeleteCita(cita);
            handleCancel();
          }}
          disabled={cita.Tipo === 'Bloqueo'}
        >
          Confirmar
        </Button>,
        <Button danger onClick={handleCancel}>
          Cerrar
        </Button>,
      ]}
    >
      <p>Estas seguro de borrar la cita?</p>
    </Modal>
  );
};

CitaBorrar.defaultProps = {};

CitaBorrar.propTypes = {};

const mapStateToProps = (state) => {
  return {
    departamentos: state.departamentos.departamentos,
    deptoId: state.estudios.deptoId,
    estudios: state.estudios.estudios,
    citas: state.citas.citasByDate,
    horarios: state.citas.horarios,
    usuario: state.auth.usuario,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteCita: (cita) => dispatch(deleteCita(cita)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CitaBorrar);
