import axiosClient from '../../config/axiosClient';
import {
  GET_DEPARTAMENTOS,
  POST_DEPARTAMENTO,
  PUT_DEPARTAMENTO,
  DELETE_DEPARTAMENTO,
  SET_DEPARTAMENTO,
} from './../types/index';

/**
 * Obtener todos lo departamentos
 */
export const getDepartamentos = () => async (dispatch) => {
  try {
    const response = await axiosClient.get('/departamentos');
    if (response.data.ok) {
      dispatch({
        type: GET_DEPARTAMENTOS,
        payload: { departamentos: response.data.departamentos },
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Crear nuevo departamento
 * @param departamento {Object}
 */
export const createDepartamento = (departamento) => async (dispatch) => {
  try {
    const response = await axiosClient.post('/departamentos', departamento);
    if (response.data.ok) {
      dispatch({
        type: POST_DEPARTAMENTO,
      });
    }
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Actualizar un departamento
 * @param departamento {Object}
 */
export const updateDepartamento = (departamento) => async (dispatch) => {
  try {
    const response = await axiosClient.put('/departamentos', departamento);
    if (response.data.ok) {
      dispatch({
        type: PUT_DEPARTAMENTO,
      });
    }
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Borrar un departamento
 * @param deptoId {number}
 */
export const deleteDepartamento = (deptoId) => async (dispatch) => {
  try {
    const response = await axiosClient.delete(`/departamentos/${deptoId}`);
    if (response.data.ok) {
      dispatch({
        type: DELETE_DEPARTAMENTO,
      });
    }
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Establecer un departamento para editar
 * @param departamento {Object}
 */
export const setDepartamento = (departamento) => async (dispatch) => {
  dispatch({
    type: SET_DEPARTAMENTO,
    payload: { departamento },
  });
};
