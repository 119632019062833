import React, { useState, useEffect } from 'react';

// redux
import { connect } from 'react-redux';
import { getDepartamentos } from './../../redux/actions/departamentosAction';
import { getRoles } from './../../redux/actions/rolesAction';
import {
  createUsuario,
  updateUsuario,
} from '../../redux/actions/usuariosAction';

// ant
import {
  Button,
  Spin,
  Form,
  Input,
  Select,
  InputNumber,
  Card,
  Row,
  Col,
} from 'antd';

// components
import { formItemLayoutModal } from './../../utils/FormItemLayout';
import { openNotification } from './../../utils/Notification';
import BreadcrumbList from './../common/BreadcrumbList';

const UsuarioFormulario = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getDeptosEstudios = async () => {
      setLoading(true);
      await Promise.all([props.onGetDepartamentos(), props.onGetRoles()]);
      setLoading(false);
    };

    getDeptosEstudios();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    props.history.goBack();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const valuesForm = await form.validateFields();
      console.log(valuesForm);
      // validar que las contraseñas sean iguales
      if (
        !props.usuario &&
        valuesForm.Password !== valuesForm.ConfirmPassword
      ) {
        openNotification('Password', 'Las passwords no coinciden', 'warning');
        return;
      }

      let response;

      if (props.usuario) {
        // console.log('Editando');
        response = await props.onUpdateUsuario({
          ...valuesForm,
          Id: props.usuario.Id,
        });
      } else {
        // console.log('Agregando');
        response = await props.onCreateUsuario(valuesForm);
      }

      if (!response) {
        openNotification(
          'Error',
          'Ocurrio un error, intente mas tarde',
          'error',
        );
      } else if (!response.ok) {
        if (response.error.name === 'SequelizeUniqueConstraintError') {
          openNotification('Error', 'El correo ya esta registrado', 'error');
        } else {
          openNotification('Error', response.message, 'error');
        }
      } else if (response.ok) {
        openNotification(
          'OK',
          `Usuario ${props.usuario ? 'editado' : 'agregado'} correctamente`,
          'success',
          3,
        );
        handleCancel();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const showOptions = (options) =>
    options.map((option) => (
      <Select.Option key={option.Id} value={option.Id}>
        {option.Nombre}
      </Select.Option>
    ));

  const showOptionsDeptos = (options) => {
    // console.log(options);
    const optionsFiltered = options.filter(
      (option) => option.Estado === 'Activo',
    );
    // console.log(optionsFiltered);
    return optionsFiltered.map((option) => (
      <Select.Option key={option.Id} value={option.Id}>
        {option.Nombre}
      </Select.Option>
    ));
  };

  return (
    <>
      <BreadcrumbList
        items={['Usuarios', props.usuario ? 'Editar' : 'Agregar']}
      />

      <Card
        title={props.usuario ? 'Editar Usuario' : 'Agregar Usuario'}
        extra={[
          <Row gutter={[16, 0]}>
            <Col>
              <Button type="primary" onClick={handleSubmit} loading={loading}>
                {props.usuario ? 'Guardar' : 'Agregar'}
              </Button>
            </Col>
            <Col>
              <Button danger onClick={handleCancel} loading={loading}>
                Cancelar
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Spin spinning={loading}>
          <Form
            {...formItemLayoutModal}
            form={form}
            name="DeptoForm"
            scrollToFirstError
            labelAlign="left"
            initialValues={props.usuario || { Estado: 'Activo' }}
          >
            {/* Nombre */}
            <Form.Item
              name="Nombre"
              label="Nombre"
              rules={[{ required: true, message: 'El nombre es requerido' }]}
            >
              <Input />
            </Form.Item>

            {/* Telefono */}
            <Form.Item
              name="Telefono"
              label="Teléfono"
              rules={[{ required: true, message: 'El teléfono es requerido' }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            {/* Correo */}
            <Form.Item
              name="Correo"
              label="Correo"
              rules={[{ required: true, message: 'El correo es requerido' }]}
            >
              <Input />
            </Form.Item>

            {!props.usuario && (
              <>
                {/* Password */}
                <Form.Item
                  name="Password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message:
                        'La password es requerida y con al menos 6 caracteres',
                      min: 6,
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                {/*Confirm Password */}
                <Form.Item
                  name="ConfirmPassword"
                  label="Confirmar Password"
                  rules={[
                    {
                      required: true,
                      message:
                        'La confirmacion es requerida y con al menos 6 caracteres',
                      min: 6,
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </>
            )}

            {/* Departamento */}
            <Form.Item
              name="Depto_FK"
              label="Departamento"
              rules={[{ required: true }]}
            >
              <Select>{showOptionsDeptos(props.departamentos)}</Select>
            </Form.Item>

            {/* Estado */}
            <Form.Item
              name="Estado"
              label="Estado"
              rules={[{ required: true }]}
            >
              <Select defaultValue="Activo">
                <Select.Option value="Activo">Activo</Select.Option>
                <Select.Option value="Inactivo">Inactivo</Select.Option>
              </Select>
            </Form.Item>

            {/* Rol */}
            <Form.Item name="Rol_FK" label="Rol" rules={[{ required: true }]}>
              <Select>{showOptions(props.roles)}</Select>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </>
  );
};

UsuarioFormulario.defaultProps = {};

UsuarioFormulario.propTypes = {};

const mapStateToProps = (state) => {
  return {
    departamentos: state.departamentos.departamentos,
    roles: state.roles.roles,
    usuario: state.usuarios.usuarioEdit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDepartamentos: () => dispatch(getDepartamentos()),
    onGetRoles: () => dispatch(getRoles()),
    onCreateUsuario: (usuario) => dispatch(createUsuario(usuario)),
    onUpdateUsuario: (usuario) => dispatch(updateUsuario(usuario)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioFormulario);
