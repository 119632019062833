import React, { useState, useEffect } from 'react';

// redux
import { connect } from 'react-redux';
import { getDepartamentos } from './../../redux/actions/departamentosAction';
import {
  getCitasByDate,
  createCita,
  updateCita,
} from '../../redux/actions/citasAction';
import { getEstudios } from './../../redux/actions/estudiosAction';

// ant
import {
  Modal,
  Button,
  Spin,
  Form,
  DatePicker,
  Select,
  Radio,
  Input,
  InputNumber,
  TimePicker,
  Row,
  Col,
} from 'antd';

// components
import { openNotification } from './../../utils/Notification';
import { formItemLayoutModal } from './../../utils/FormItemLayout';

import moment from 'moment';

const CitaFormulario = (props) => {
  // console.log(props.fechaCita);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [estudios, setEstudios] = useState(props.estudios);
  const [departamentos, setDepartamentos] = useState(props.departamentos);
  const [horarios, setHorarios] = useState(null);
  const [tipoCita, setTipoCita] = useState('Normal');

  useEffect(() => {
    const getDeptosEstudios = async () => {
      setLoading(true);
      await Promise.all([
        props.onGetDepartamentos(),
        props.onGetEstudios(props.usuario.departamento.Nombre),
      ]);
      // generarHorarios();
      setLoading(false);
    };

    getDeptosEstudios();

    if (props.fechaCita) {
      onChangeFechaCita(moment(props.fechaCita.start));

      form.setFieldsValue({
        Hora_Inicio: `${props.fechaCita.start
          .getHours()
          .toString()
          .padStart(
            2,
            '0',
          )}:${props.fechaCita.start
          .getMinutes()
          .toString()
          .padStart(2, '0')}:00`,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // establecer los estudios cuando se carguen
  useEffect(() => {
    setEstudios(props.estudios);
  }, [props.estudios]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const valuesForm = await form.validateFields();

      let response;

      if (props.cita) {
        response = await props.onUpdateCita({
          ...valuesForm,
          Id: props.cita.Id,
        });
      } else {
        const fechaCita = new Date(valuesForm.Fecha_Cita._d);
        fechaCita.setHours(0, 0, 0);

        let cita;

        if (tipoCita === 'Extraordinaria') {
          const horaInicio = new Date(valuesForm.Horario[0]._d);
          const horaInicioString = `${horaInicio
            .getHours()
            .toString()
            .padStart(2, '0')}:${horaInicio
            .getMinutes()
            .toString()
            .padStart(2, '0')}:00`;

          const horaFin = new Date(valuesForm.Horario[1]._d);
          const horaFinString = `${horaFin
            .getHours()
            .toString()
            .padStart(2, '0')}:${horaFin
            .getMinutes()
            .toString()
            .padStart(2, '0')}:00`;

          cita = {
            ...valuesForm,
            Depto_FK: props.deptoId,
            Fecha_Cita: fechaCita,
            Hora_Inicio: horaInicioString,
            Hora_Fin: horaFinString,
          };
        } else {
          const horario = props.horarios.find(
            (h) => h.Id === valuesForm.Hora_Inicio,
          );
          cita = {
            ...valuesForm,
            Depto_FK: props.deptoId,
            Fecha_Cita: fechaCita,
            Hora_Inicio: horario.Hora_Inicio,
            Hora_Fin: horario.Hora_Fin,
          };
        }

        response = await props.onCreateCita(cita);
      }

      if (response.ok) {
        if (response.disponible) {
          openNotification('Ok', 'Cita creada correctamente', 'success', 3);
          props.getCitas();
          handleCancel();
        } else if (response.cita) {
          openNotification('Ok', 'Estado de cita cambiado', 'success', 3);
          props.getCitas();
          handleCancel();
        } else {
          openNotification('Problema', 'El horario ya esta ocupado', 'warning');

          form.setFieldsValue({
            Hora_Inicio: '',
          });
          setHorarios(null);
        }
      } else {
        openNotification(
          'Error',
          'Ocurrio un error al intentar crear la cita',
          'error',
        );
      }

      // console.log(response);
    } catch (error) {
      console.log(error);
      openNotification(
        'Error',
        'Ocurrio un error al intentar crear la cita',
        'error',
      );
    } finally {
      setLoading(false);
    }
  };

  const onChangeDepto = async (value) => {
    setLoading(true);
    // setDepto(value);
    try {
      form.setFieldsValue({
        Estudio_FK: '',
        Hora_Inicio: '',
        Fecha_Cita: '',
      });
      setHorarios(null);
    } catch (error) {
      console.log(error);
    }
    await props.onGetEstudios(value);
    setLoading(false);
  };

  const onChangeFechaCita = async (value) => {
    if (tipoCita === 'Normal' || tipoCita === 'Bloqueo') {
      try {
        form.setFieldsValue({
          Hora_Inicio: '',
        });
        setHorarios(null);
      } catch (error) {
        console.log(error);
      }
      if (value?._d) {
        setLoading(true);
        const fechaFilter = new Date(value._d);
        // sacar unicamente la fecha en formato YYYY-MM-DD sin hora
        const fechaString = `${fechaFilter.getFullYear()}-${(
          fechaFilter.getMonth() + 1
        )
          .toString()
          .padStart(2, '0')}-${fechaFilter.getDate()}`;
        const { citas, horarios } = await props.onGetCitasByDate(
          fechaString,
          props.deptoId,
        );
        // console.log(props.citas);
        const horariosLocal = horarios;
        // console.log(props.horarios);
        if (citas.length) {
          for (let i = 0; i < horariosLocal.length; i++) {
            for (let j = 0; j < citas.length; j++) {
              // console.log(horarios[i].hora === citasResponse[j].Hora_Inicio);
              if (horariosLocal[i].Hora_Inicio === citas[j].Hora_Inicio) {
                horariosLocal[i].Disponible = false;
                // console.log(horariosLocal[i].Hora_Inicio);
                // console.log(citas[j].Hora_Inicio);
              }
            }
          }
        }
        setHorarios(horariosLocal);

        setLoading(false);
      }
    }
  };

  const onChangeTipo = (value) => {
    // console.log(value);
    setTipoCita(value);
    try {
      form.setFieldsValue({
        Hora_Inicio: '',
        Fecha_Cita: '',
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    props.setVisible(false);
  };

  const handleSearchEstudio = (value) => {
    if (value.trim() !== '') {
      const estudiosFilter = props.estudios.filter((estudio) =>
        estudio.Nombre.toLowerCase().includes(value),
      );
      setEstudios(estudiosFilter);
    } else {
      setEstudios(props.estudios);
    }
  };

  const handleSearchDepto = (value) => {
    if (value.trim() !== '') {
      const deptosFilter = props.departamentos.filter((depto) =>
        depto.Nombre.toLowerCase().includes(value),
      );
      setDepartamentos(deptosFilter);
    } else {
      setDepartamentos(props.estudios);
    }
  };

  const showOptions = (options) => {
    const optionsFiltered = options.filter(
      (option) => option.Estado === 'Activo',
    );

    return optionsFiltered.map((option) => (
      <Select.Option key={option.Id} value={option.Id}>
        {option.Nombre}
      </Select.Option>
    ));
  };

  const showOptionsHorario = (options) =>
    options.map((option) => (
      <Select.Option
        key={option.Id}
        value={option.Id}
        disabled={!option.Disponible}
      >
        {option.Hora_Inicio}
      </Select.Option>
    ));

  const showOptionsDeptos = (options) => {
    // console.log(options);
    const optionsFiltered = options.filter(
      (option) => option.Estado === 'Activo',
    );
    // console.log(optionsFiltered);
    return optionsFiltered.map((option) => (
      <Select.Option key={option.Id} value={option.Nombre}>
        {option.Nombre}
      </Select.Option>
    ));
  };

  return (
    <Modal
      visible={props.visible}
      title={props.cita ? 'Editar Estado' : 'Agregar Cita'}
      closable={false}
      footer={[
        <Button type="primary" onClick={handleSubmit} loading={loading}>
          {props.cita ? 'Guardar' : 'Agregar'}
        </Button>,
        <Button danger onClick={handleCancel} loading={loading}>
          Cancelar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form
          {...formItemLayoutModal}
          form={form}
          name="CitaForm"
          scrollToFirstError
          labelAlign="left"
          // layout='vertical'
          initialValues={
            props.cita || {
              Depto_FK: props.usuario.departamento.Nombre,
              Tipo: 'Normal',
              Estado_Cita: 'Programada',
            }
          }
          // initialValues={
          //   props.cita ||
          //   (props.fechaCita !== null && props.fechaCita !== undefined)
          //     ? {
          //         Depto_FK: props.usuario.departamento.Nombre,
          //         Tipo: 'Normal',
          //         Estado_Cita: 'Programada',
          //         Fecha_Cita: moment(
          //           new Date(props.fechaCita.start),
          //           'DD/MM/YYYY',
          //         ),
          //       }
          //     : {
          //         Depto_FK: props.usuario.departamento.Nombre,
          //         Tipo: 'Normal',
          //         Estado_Cita: 'Programada',
          //       }
          // }
        >
          <Row gutter={[8, 0]}>
            {/* Tipo de cita */}
            {!props.cita && (
              <Col span={12}>
                <Form.Item
                  name="Tipo"
                  label="Tipo"
                  rules={[{ required: true }]}
                  // style={{ marginBottom: '16px' }}
                >
                  <Select defaultValue="Normal" onChange={onChangeTipo}>
                    {/* {showOptionsDeptos(props.departamentos)} */}
                    <Select.Option value="Normal">Normal</Select.Option>
                    <Select.Option value="Bloqueo">Bloqueo</Select.Option>
                    <Select.Option value="Extraordinaria">
                      Extraordinaria
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            )}

            {/* Estado de cita*/}
            <Col span={props.cita ? 24 : 12}>
              <Form.Item
                name="Estado_Cita"
                label="Estado"
                rules={[{ required: true }]}
                // style={{ marginBottom: '16px' }}
              >
                <Select defaultValue="">
                  <Select.Option value="Programada">Programada</Select.Option>
                  <Select.Option value="Registrada">Registrada</Select.Option>
                  <Select.Option value="En Proceso">En Proceso</Select.Option>
                  <Select.Option value="Bloqueada">Bloqueada</Select.Option>
                  <Select.Option value="Asistió">Asistió</Select.Option>
                  <Select.Option value="No Asistió">No Asistió</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {!props.cita && (
            <>
              {/* Departamento */}
              <Form.Item
                name="Depto_FK"
                label="Departamento"
                rules={[{ required: true }]}
                // style={{ marginBottom: '16px' }}
              >
                <Select
                  showSearch
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  filterOption={false}
                  onSearch={handleSearchDepto}
                  onChange={onChangeDepto}
                  notFoundContent={null}
                >
                  {showOptionsDeptos(departamentos)}
                </Select>
              </Form.Item>

              {(tipoCita === 'Normal' || tipoCita === 'Extraordinaria') && (
                <>
                  {/* Estudio */}
                  <Form.Item
                    name="Estudio_FK"
                    label="Estudio"
                    rules={[{ required: true }]}
                    // style={{ marginBottom: '16px' }}
                  >
                    <Select
                      showSearch
                      defaultActiveFirstOption={false}
                      showArrow={true}
                      filterOption={false}
                      onSearch={handleSearchEstudio}
                      notFoundContent={null}
                    >
                      {showOptions(estudios)}
                    </Select>
                  </Form.Item>

                  {/* Nombre */}
                  <Form.Item
                    name="Nombre"
                    label="Nombre"
                    rules={[{ required: true }]}
                    // style={{ marginBottom: '16px' }}
                  >
                    <Input type="text" />
                  </Form.Item>

                  {/* Apellido Paterno */}
                  <Form.Item
                    name="Apellido_Paterno"
                    label="Apellido Paterno"
                    rules={[{ required: true }]}
                    // style={{ marginBottom: '16px' }}
                  >
                    <Input type="text" />
                  </Form.Item>

                  {/* Apellido Materno */}
                  <Form.Item
                    name="Apellido_Materno"
                    label="Apellido Materno"
                    rules={[{ required: true }]}
                    // style={{ marginBottom: '16px' }}
                  >
                    <Input type="text" />
                  </Form.Item>

                  {/* Fecha de nacimiento */}
                  <Form.Item
                    name="Fecha"
                    label="Fecha de nacimiento"
                    rules={[{ required: true }]}
                    // style={{ marginBottom: '16px' }}
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      format={'DD/MM/YYYY'}
                      placeholder="dd/mm/aaaa"
                    />
                  </Form.Item>

                  {/* Correo electronico */}
                  <Form.Item
                    name="Correo"
                    label="Correo Electrónico"
                    // style={{ marginBottom: '16px' }}
                  >
                    <Input type="text" />
                  </Form.Item>

                  <Row>
                    {/* Telefono */}
                    <Col span={11}>
                      <Form.Item
                        name="Telefono"
                        label="Teléfono"
                        rules={[{ required: true }]}
                        // style={{ marginBottom: '16px' }}
                      >
                        <InputNumber style={{ width: '90%' }} />
                      </Form.Item>
                    </Col>

                    <Col span={13}>
                      {/* Sexo */}
                      <Form.Item
                        name="Sexo"
                        label="Sexo"
                        rules={[{ required: true }]}
                        wrapperCol={18}
                        labelCol={6}
                        // style={{ marginBottom: '16px' }}
                      >
                        <Radio.Group>
                          <Radio value="Hombre">Hombre</Radio>
                          <Radio value="Mujer">Mujer</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              {/* Fecha de cita */}
              <Row>
                <Col span={11}>
                  <Form.Item
                    name="Fecha_Cita"
                    label="Fecha"
                    rules={[{ required: true }]}
                    // style={{ marginBottom: '16px' }}
                  >
                    <DatePicker
                      style={{ width: '90%' }}
                      format={'DD/MM/YYYY'}
                      // defaultValue={moment()}
                      onChange={onChangeFechaCita}
                      placeholder="dd/mm/aaaa"
                    />
                  </Form.Item>
                </Col>

                {/* Hora de cita */}
                {tipoCita === 'Extraordinaria' ? (
                  <Col span={13}>
                    <Form.Item
                      name="Horario"
                      label="Horario"
                      rules={[{ required: true }]}
                      // style={{ marginBottom: '16px' }}
                    >
                      <TimePicker.RangePicker
                        style={{ width: '100%' }}
                        format="HH:mm"
                        placeholder={['Inicio', 'Fin']}
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col span={13}>
                    <Form.Item
                      name="Hora_Inicio"
                      label="Horario"
                      rules={[{ required: true }]}
                      // style={{ marginBottom: '16px' }}
                    >
                      <Select defaultValue="" disabled={horarios === null}>
                        {horarios && showOptionsHorario(horarios)}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

CitaFormulario.defaultProps = {};

CitaFormulario.propTypes = {};

const mapStateToProps = (state) => {
  return {
    departamentos: state.departamentos.departamentos,
    deptoId: state.estudios.deptoId,
    estudios: state.estudios.estudios,
    citas: state.citas.citasByDate,
    horarios: state.citas.horarios,
    usuario: state.auth.usuario,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateCita: (cita) => dispatch(createCita(cita)),
    onGetDepartamentos: () => dispatch(getDepartamentos()),
    onGetEstudios: (deptoNombre) => dispatch(getEstudios(deptoNombre)),
    onGetCitasByDate: (date, deptoId) =>
      dispatch(getCitasByDate(date, deptoId)),
    onUpdateCita: (cita) => dispatch(updateCita(cita)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CitaFormulario);
