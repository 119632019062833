import React from 'react';

// ant
import { Table, Card, Button } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';

// components
import ButtonTooltip from '../common/ButtonTooltip';
import ButtonPopconfirm from './../common/ButtonPopconfirm';

const { Column } = Table;

const DeptoTable = (props) => {
  return (
    <Card
      title="Departamentos"
      extra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={props.addDepartamento}
        >
          Agregar
        </Button>
      }
    >
      <Table
        dataSource={props.departamentos}
        size="small"
        width="auto"
        height="auto"
        pagination={{ pageSize: 20 }}
        scroll={{ x: 800 }}
        rowKey="Id"
      >
        {/* <Column title="Id" dataIndex="Id" /> */}
        <Column title="Nombre" dataIndex="Nombre" />
        <Column title="Estado" dataIndex="Estado" />
        <Column
          title="Color"
          dataIndex="Color"
          render={(text) => (
            <div
              style={{
                width: '72px',
                backgroundColor: text,
                height: '100%',
                padding: '10px',
              }}
            />
          )}
        />
        <Column
          title="Acciones"
          width={150}
          fixed="right"
          render={(text, record) => (
            <>
              <ButtonTooltip
                icon={EditOutlined}
                title="Editar"
                onClick={() => props.editDepartamento(record)}
              />
              <ButtonTooltip
                icon={ClockCircleOutlined}
                title="Agenda"
                onClick={() => props.gotoAgenda(record)}
              />
              <ButtonPopconfirm
                icon={DeleteOutlined}
                title="¿Desea eliminar el departamento seleccionado?"
                tooltip="Eliminar"
                onConfirm={() => props.deleteDepartamento(record.Id)}
              />
            </>
          )}
        />
      </Table>
    </Card>
  );
};

DeptoTable.defaultProps = {};

DeptoTable.propTypes = {};

export default DeptoTable;
