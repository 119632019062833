import {
  DELETE_CITA,
  GET_ALL_CITAS,
  GET_CITAS,
  GET_CITAS_BY_DATE,
} from './../types/index';

const initialState = {
  citas: [],
  citasByDate: [],
  horarios: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CITAS:
    case GET_CITAS:
      return { ...state, citas: action.payload.citas };
    case GET_CITAS_BY_DATE:
      return {
        ...state,
        citasByDate: action.payload.citasByDate,
        horarios: action.payload.horarios,
      };
    case DELETE_CITA:
      const citas = state.citas.filter(
        (cita) => cita.Id !== action.payload.citaId,
      );
      return { ...state, citas };
    default:
      return state;
  }
};
