import React, { useState } from 'react';

// redux
import { connect } from 'react-redux';
import {
  createEstudio,
  updateEstudio,
} from '../../redux/actions/estudiosAction';

// ant
import {
  Button,
  Form,
  Spin,
  Input,
  Select,
  InputNumber,
  Card,
  Row,
  Col,
} from 'antd';

// components
import { formItemLayoutModal } from './../../utils/FormItemLayout';
import { openNotification } from './../../utils/Notification';
import BreadcrumbList from '../common/BreadcrumbList';

const EstudioFormulario = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    props.history.goBack();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const valuesForm = await form.validateFields();
      let response;
      if (props.estudio) {
        // console.log('Editando');
        response = await props.onUpdateEstudio({
          ...valuesForm,
          Id: props.estudio.Id,
        });
      } else {
        // console.log('Agregando');
        response = await props.onCreateEstudio({
          ...valuesForm,
          Depto_FK: props.deptoId,
        });
      }

      if (!response) {
        openNotification(
          'Error',
          'Ocurrio un error, intente mas tarde',
          'error',
        );
      } else if (!response.ok) {
        openNotification('Error', response.message, 'error');
      } else if (response.ok) {
        openNotification(
          'OK',
          `Estudio ${props.estudio ? 'editado' : 'agregado'} correctamente`,
          'success',
          3,
        );
        handleCancel();
      }
    } catch (error) {
      console.log(error);
      openNotification('Error', error, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BreadcrumbList
        items={['Estudios', props.estudio ? 'Editar' : 'Agregar']}
      />
      <Card
        title={props.estudio ? 'Editar Estudio' : 'Agregar Estudio'}
        extra={[
          <Row gutter={[16, 0]}>
            <Col>
              <Button type="primary" onClick={handleSubmit} loading={loading}>
                {props.estudio ? 'Guardar' : 'Agregar'}
              </Button>
            </Col>
            <Col>
              <Button danger onClick={handleCancel} loading={loading}>
                Cancelar
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Spin spinning={loading}>
          <Form
            {...formItemLayoutModal}
            form={form}
            name="EstudioForm"
            scrollToFirstError
            labelAlign="left"
            initialValues={props.estudio || { Estado: 'Activo' }}
          >
            {/* Nombre */}
            <Form.Item
              name="Nombre"
              label="Nombre"
              rules={[{ required: true, message: 'El nombre es requerido' }]}
            >
              <Input />
            </Form.Item>

            {/* Informacion */}
            <Form.Item
              name="Informacion"
              label="Información"
              rules={[
                { required: true, message: 'La informacion es requerida' },
              ]}
            >
              <Input.TextArea rows={2} />
            </Form.Item>

            {/* Notas */}
            <Form.Item name="Notas" label="Notas">
              <Input.TextArea rows={3} />
            </Form.Item>

            {/* Precio */}
            <Form.Item name="Precio" label="Precio">
              <InputNumber
                style={{ width: '100%' }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                precision={2}
              />
            </Form.Item>

            {/* Estado */}
            <Form.Item
              name="Estado"
              label="Estado"
              rules={[{ required: true }]}
            >
              <Select defaultValue="Activo">
                <Select.Option value="Activo">Activo</Select.Option>
                <Select.Option value="Inactivo">Inactivo</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </>
  );
};

EstudioFormulario.defaultProps = {};

EstudioFormulario.propTypes = {};

const mapStateToProps = (state) => {
  return {
    deptoId: state.estudios.deptoId,
    estudio: state.estudios.estudioEdit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateEstudio: (estudio) => dispatch(createEstudio(estudio)),
    onUpdateEstudio: (estudio) => dispatch(updateEstudio(estudio)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EstudioFormulario);
