import React, { useState, useEffect } from 'react';

// redux
import { connect } from 'react-redux';
import { getAllCitas, getCitas } from '../../redux/actions/citasAction';
import { getDepartamentos } from './../../redux/actions/departamentosAction';

// ant
import { Spin, Row, Col, Select } from 'antd';

// components
import BreadcrumbList from './../common/BreadcrumbList';
import CitasCalendario from './CitasCalendario';
import CitaFormulario from './CitaFormulario';
import { openNotification } from './../../utils/Notification';

const CitasIndex = (props) => {
  const [loading, setLoading] = useState(false);
  const [openCitaForm, setOpenCitaForm] = useState(false);
  const [departamento, setDepartamento] = useState(null);
  const [fechaCita, setFechaCita] = useState(null);

  useEffect(() => {
    getCitas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCitas = async () => {
    setLoading(true);
    // verificar que el usuario este actvo
    if (props.usuario.departamento.Estado === 'Activo') {
      await props.onGetCitas(departamento || props.usuario.Depto_FK);
      // si es admin traer citas de todos los departamentos
      if (props.usuario.role.Nombre === 'ADMIN') {
        await Promise.all([props.onGetAllCitas(), props.onGetDepartamentos()]);
      }
    } else {
      openNotification(
        'Inactivo',
        'Departamento inactivo, no se pueden ver las citas',
        'warning',
        4,
      );
    }

    setLoading(false);
  };

  const addCita = () => {
    setFechaCita(null);
    setOpenCitaForm(true);
  };

  const addCitaWithDate = (fecha) => {
    // console.log(fecha);
    setFechaCita(fecha);
    setOpenCitaForm(true);
  };

  const showOptionsDeptos = (options) => {
    // console.log(options);
    const optionsFiltered = options.filter(
      (option) => option.Estado === 'Activo',
    );
    // console.log(optionsFiltered);
    return optionsFiltered.map((option) => (
      <Select.Option key={option.Id} value={option.Id}>
        {option.Nombre}
      </Select.Option>
    ));
  };

  const onChange = async (e) => {
    setLoading(true);
    if (e === 'all') {
      await props.onGetAllCitas();
    } else {
      await props.onGetCitas(e);
    }
    setDepartamento(e);
    setLoading(false);
    // console.log(e);
  };

  return (
    <>
      <BreadcrumbList items={['Citas', 'Inicio']} />

      {props.usuario.role.Nombre === 'ADMIN' && (
        <Row justify="start" style={{ marginBottom: '1rem' }}>
          <Col xs={24} md={8} style={{ textAlign: 'center' }}>
            <Spin spinning={loading}>
              <Select
                style={{ width: '100%' }}
                defaultValue={departamento || 'all'}
                onChange={onChange}
              >
                <Select.Option value="" disabled>
                  Seleccione un departamento
                </Select.Option>
                <Select.Option value="all">Todas las citas</Select.Option>
                {showOptionsDeptos(props.departamentos)}
              </Select>
            </Spin>
          </Col>
        </Row>
      )}

      {/* Citas */}
      <Spin spinning={loading}>
        <CitasCalendario
          citas={props.citas}
          addCita={addCita}
          getCitas={getCitas}
          addCitaWithDate={addCitaWithDate}
        />
      </Spin>

      {/* Citas formulario */}
      {openCitaForm && (
        <CitaFormulario
          visible={openCitaForm}
          setVisible={setOpenCitaForm}
          getCitas={getCitas}
          fechaCita={fechaCita}
        />
      )}
    </>
  );
};

CitasIndex.defaultProps = {};

CitasIndex.propTypes = {};

const mapStateToProps = (state) => {
  return {
    usuario: state.auth.usuario,
    citas: state.citas.citas,
    departamentos: state.departamentos.departamentos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCitas: (deptoId) => dispatch(getCitas(deptoId)),
    onGetAllCitas: () => dispatch(getAllCitas()),
    onGetDepartamentos: () => dispatch(getDepartamentos()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CitasIndex);
