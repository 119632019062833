import React, { useState, useEffect } from 'react';

// redux
import { connect } from 'react-redux';
import { login, getAuthenticatedUser } from './../../redux/actions/authAction';

// ant
import { Form, Input, Button, Row, Col, Spin } from 'antd';

// utils
import { formButton } from './../../utils/FormItemLayout';
import { openNotification } from './../../utils/Notification';
import { URL_LOGO_VERTICAL } from '../../utils/Constantes';

const LoginIndex = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkLogin();
    return () => {
      console.log(loading);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkLogin = async () => {
    const response = await props.onGetUserAuthenticated();
    setLoading(false);
    if (response && response.ok && response.usuario) {
      // si el token devuelve una respuesta correcta iniciar seseion
      props.history.replace('/');
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    // obtener valores del formulario
    try {
      const valuesForm = await form.validateFields();
      const response = await props.login(valuesForm);
      setLoading(false);
      if (!response) {
        openNotification(
          'Error',
          'Ocurrio un error, intente mas tarde',
          'error',
        );
      } else if (response && !response.ok) {
        openNotification('Error', response.message, 'error');
      } else if (response.ok) {
        // console.log('replace');
        setLoading(false);
        props.history.replace('/');
      }
    } catch (error) {
      setLoading(false);
      // error de validacion de campos
      if (error.outOfDate === false) {
        openNotification(
          'Error',
          'Todos los campos son obligatorios y con el formato adecuado',
          'error',
        );
        return;
      }
    }
  };

  return (
    <>
      <Row
        justify="center"
        align="middle"
        className="login"
        style={{ minHeight: '100vh' }}
      >
        <Col span={24}>
          <Row justify="center" align="middle" style={{ marginBottom: '2rem' }}>
            <img
              src={URL_LOGO_VERTICAL}
              alt="..."
              style={{ maxWidth: '80vw' }}
            />
          </Row>
          {/* <Row justify="center"> */}
          <Spin spinning={loading}>
            <Form name="login" form={form} size="large">
              <Form.Item
                wrapperCol={formButton}
                name="correo"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Ingrese su correo',
                  },
                ]}
              >
                <Input placeholder="Correo" />
              </Form.Item>
              <Form.Item
                wrapperCol={formButton}
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'La contraseña debe tener al menos 6 caracteres',
                    min: 6,
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item wrapperCol={formButton}>
                <Button
                  type="primary"
                  block
                  style={{
                    background: '#3dbfe7',
                    borderColor: '#3dbfe7',
                    fontWeight: 'bold',
                    // color: '#4a3088',
                  }}
                  // style={{
                  //   background: '#4a3088',
                  //   borderColor: '#4a3088',
                  //   color: '#3dbfe7',
                  // }}
                  // loading={isLoading}
                  onClick={handleSubmit}
                >
                  Iniciar Sesión
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Col>
      </Row>
    </>
  );
};

LoginIndex.defaultProps = {};

LoginIndex.propTypes = {};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (user) => dispatch(login(user)),
    onGetUserAuthenticated: () => dispatch(getAuthenticatedUser()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginIndex);
