import axiosClient from '../../config/axiosClient';
import {
  GET_CITAS,
  GET_CITAS_BY_DATE,
  CREATE_CITA,
  PUT_CITA,
  GET_ALL_CITAS,
  DELETE_CITA,
} from './../types/index';

/**
 * Crear nueva cita
 * @param citas {Object}
 */
export const createCita = (cita) => async (dispatch) => {
  try {
    const response = await axiosClient.post('/citas', cita);
    if (response.data.ok && response.data.disponible) {
      dispatch({
        type: CREATE_CITA,
        payload: { citas: response.data.citas },
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Obtener todas las citas
 */
export const getAllCitas = () => async (dispatch) => {
  try {
    const response = await axiosClient.get('/citas');
    if (response.data.ok) {
      dispatch({
        type: GET_ALL_CITAS,
        payload: { citas: response.data.citas },
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Obtener citas por departamento
 * @param deptoId {number}
 */
export const getCitas = (deptoId) => async (dispatch) => {
  try {
    const response = await axiosClient.get(`/citas/${deptoId}`);
    if (response.data.ok) {
      dispatch({
        type: GET_CITAS,
        payload: { citas: response.data.citas },
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Obtener citas por departamento y horario
 * @param date {date}
 * @param deptoId {number}
 */
export const getCitasByDate = (date, deptoId) => async (dispatch) => {
  try {
    const response = await axiosClient.get(`/citas/${date}/${deptoId}`);
    if (response.data.ok) {
      dispatch({
        type: GET_CITAS_BY_DATE,
        payload: {
          citasByDate: response.data.citas,
          horarios: response.data.horarios,
        },
      });
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * Actualizar estado de una cita
 * @param cita {Object}
 */
export const updateCita = (cita) => async (dispatch) => {
  try {
    const response = await axiosClient.put('/citas', cita);
    if (response.data.ok) {
      dispatch({
        type: PUT_CITA,
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Borrar una cita
 * @param cita {Object}
 */
export const deleteCita = (cita) => async (dispatch) => {
  try {
    const response = await axiosClient.delete(`/citas/${cita.Id}`, cita);
    if (response.data.ok) {
      dispatch({
        type: DELETE_CITA,
        payload: { citaId: cita.Id },
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
