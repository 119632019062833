import React, { useState, useEffect } from 'react';

// redux
import { connect } from 'react-redux';
import {
  getCupones,
  setCupon,
  deleteCupon,
} from './../../redux/actions/cuponesAction';

// ant
import { Spin } from 'antd';

// components
import CuponesTable from './CuponesTable';
import BreadcrumbList from './../common/BreadcrumbList';

const CuponesIndex = (props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCupones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCupones = async () => {
    setLoading(true);
    await props.onGetCupones();
    setLoading(false);
  };

  const addCupones = () => {
    props.onSetCupon(null);
    props.history.push('/cupones/agregar');
  };

  const editCupon = (cupon) => {
    props.onSetCupon(cupon);
    props.history.push('/cupones/editar');
  };

  const deleteCupon = async (cuponId) => {
    setLoading(true);
    await props.onDeleteCupon(cuponId);
    getCupones();
    setLoading(false);
  };

  return (
    <>
      <BreadcrumbList items={['Cupones', 'Inicio']} />
      {/* Tabla de cupones */}
      <Spin spinning={loading}>
        <CuponesTable
          cupones={props.cupones}
          addCupones={addCupones}
          editCupon={editCupon}
          deleteCupon={deleteCupon}
        />
      </Spin>
    </>
  );
};

CuponesIndex.defaultProps = {};

CuponesIndex.propTypes = {};

const mapStateToProps = (state) => {
  return {
    cupones: state.cupones.cupones,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCupones: () => dispatch(getCupones()),
    onSetCupon: (cupon) => dispatch(setCupon(cupon)),
    onDeleteCupon: (cuponId) => dispatch(deleteCupon(cuponId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CuponesIndex);
