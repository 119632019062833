import { GET_ROLES } from './../types/index';

const initialState = {
  roles: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES:
      return { ...state, roles: action.payload.roles };

    default:
      return state;
  }
};
