import React, { useState, useEffect } from 'react';

// redux
import { connect } from 'react-redux';
import { getDepartamentos } from './../../redux/actions/departamentosAction';

// components
import BreadcrumbList from './../common/BreadcrumbList';
import {
  Card,
  Row,
  Col,
  Button,
  Spin,
  Form,
  Input,
  Select,
  DatePicker,
} from 'antd';

// utils
import { formItemLayoutModal } from './../../utils/FormItemLayout';
import { openNotification } from './../../utils/Notification';
import { createCupon, updateCupon } from '../../redux/actions/cuponesAction';
import moment from 'moment';

const CuponesFormulario = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInfo = async () => {
    setLoading(true);
    await props.onGetDepartamentos();
    setLoading(false);
  };

  const handleCancel = () => {
    props.history.goBack();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const valuesForm = await form.validateFields();
      // console.log(valuesForm);

      // return;
      // eslint-disable-next-line no-unreachable
      // poner las horas en cero 00:00:00
      const Vigencia_Inicio = new Date(valuesForm.Vigencia_Inicio._d);
      Vigencia_Inicio.setHours(0, 0, 0);
      // poner las horas en 23:59:00
      const Vigencia_Fin = new Date(valuesForm.Vigencia_Fin._d);
      Vigencia_Fin.setHours(23, 59, 0);

      let response;
      if (props.cupon) {
        // console.log('Editando');
        response = await props.onUpdateCupon({
          ...valuesForm,
          Id: props.cupon.Id,
          Vigencia_Inicio,
          Vigencia_Fin,
          Fecha_Alta: new Date(valuesForm.Fecha_Alta._d),
        });
      } else {
        // console.log('Agregando');

        response = await props.onCreateCupon({
          ...valuesForm,
          Vigencia_Inicio,
          Vigencia_Fin,
          Fecha_Alta: new Date(valuesForm.Fecha_Alta._d),
        });
      }

      if (!response) {
        openNotification(
          'Error',
          'Ocurrio un error, intente mas tarde',
          'error',
        );
      } else if (!response.ok) {
        openNotification('Error', response.message, 'error');
      } else if (response.ok) {
        openNotification(
          'OK',
          `Cupon ${props.cupon ? 'editado' : 'agregado'} correctamente`,
          'success',
          3,
        );
        handleCancel();
      }
    } catch (error) {
      console.log(error);
      if (error.errorFields?.length) {
        openNotification(
          'Error',
          'Favor de llenar los campos requeridos',
          'error',
        );
      } else {
        openNotification('Error', error.toString(), 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const showOptions = (options) => {
    // const optionsFiltered = options.filter(
    //   (option) => option.Estado === 'Activo',
    // );

    return options.map((option) => (
      <Select.Option key={option.Id} value={option.Id}>
        {option.Nombre}
      </Select.Option>
    ));
  };

  return (
    <>
      <BreadcrumbList items={['Cupones', props.cupon ? 'Editar' : 'Agregar']} />
      <Card
        title={props.cupon ? 'Editar Cupón' : 'Agregar Cupón'}
        extra={[
          <Row gutter={[16, 0]}>
            <Col>
              <Button type="primary" onClick={handleSubmit} loading={loading}>
                {props.cupon ? 'Editar' : 'Agregar'}
              </Button>
            </Col>
            <Col>
              <Button danger onClick={handleCancel} loading={loading}>
                Cancelar
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Spin spinning={loading}>
          <Form
            {...formItemLayoutModal}
            form={form}
            name="DeptoForm"
            scrollToFirstError
            labelAlign="left"
            initialValues={
              props.cupon
                ? {
                    ...props.cupon,
                    Vigencia_Inicio: moment(
                      new Date(props.cupon.Vigencia_Inicio),
                      'DD/MM/YYYY',
                    ),
                    Vigencia_Fin: moment(
                      new Date(props.cupon.Vigencia_Fin),
                      'DD/MM/YYYY',
                    ),
                    Fecha_Alta: moment(
                      new Date(props.cupon.Fecha_Alta),
                      'DD/MM/YYYY',
                    ),
                  }
                : {
                    Estado: 'Activo',
                  }
            }
          >
            {/* Código */}
            <Form.Item
              name="Codigo"
              label="Código"
              rules={[
                {
                  required: true,
                  message: 'El código es requerido',
                },
              ]}
            >
              <Input />
            </Form.Item>

            {/* Departamento */}
            <Form.Item
              name="Depto_FK"
              label="Departamento"
              rules={[
                {
                  required: true,
                  message: 'El departamento es requerido',
                },
              ]}
            >
              <Select defaultValue="">
                {showOptions(props.departamentos)}
              </Select>
            </Form.Item>

            {/* Inicio Vigencia */}
            <Form.Item
              name="Vigencia_Inicio"
              label="Inicio de vigencia"
              rules={[{ required: true }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={'DD/MM/YYYY'}
                placeholder="dd/mm/aaaa"
              />
            </Form.Item>
            {/* Fin Vigencia */}
            <Form.Item
              name="Vigencia_Fin"
              label="Fin de vigencia"
              rules={[{ required: true }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={'DD/MM/YYYY'}
                placeholder="dd/mm/aaaa"
              />
            </Form.Item>
            {/* Fecha alta */}
            <Form.Item
              name="Fecha_Alta"
              label="Fecha de alta"
              rules={[{ required: true }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={'DD/MM/YYYY'}
                disabled={props.cupon}
                placeholder="dd/mm/aaaa"
              />
            </Form.Item>

            {/* Estado */}
            <Form.Item
              name="Estado"
              label="Estado"
              rules={[{ required: true }]}
            >
              <Select defaultValue="Activo">
                <Select.Option value="Planeado">Planeado</Select.Option>
                <Select.Option value="Activo">Activo</Select.Option>
                <Select.Option value="Inactivo">Inactivo</Select.Option>
                <Select.Option value="Cancelado">Cancelado</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </>
  );
};

CuponesFormulario.defaultProps = {};

CuponesFormulario.propTypes = {};

const mapStateToProps = (state) => {
  return {
    departamentos: state.departamentos.departamentos,
    cupon: state.cupones.cuponeEdit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDepartamentos: () => dispatch(getDepartamentos()),
    onCreateCupon: (cupon) => dispatch(createCupon(cupon)),
    onUpdateCupon: (cupon) => dispatch(updateCupon(cupon)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CuponesFormulario);
