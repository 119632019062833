import React from 'react';
import PropTypes from 'prop-types';

import { Breadcrumb } from 'antd';

const BreadcrumbList = (props) => {
  return (
    <Breadcrumb style={{ marginBottom: '1rem' }}>
      {props.items.map((item, index) => (
        <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

BreadcrumbList.propTypes = {
  items: PropTypes.array.isRequired,
};

export default BreadcrumbList;
