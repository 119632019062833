import { GET_DEPARTAMENTOS, SET_DEPARTAMENTO } from './../types/index';

const initialState = {
  departamentos: [],
  departamentoEdit: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPARTAMENTOS:
      return { ...state, departamentos: action.payload.departamentos };
    case SET_DEPARTAMENTO:
      return { ...state, departamentoEdit: action.payload.departamento };
    default:
      return state;
  }
};
