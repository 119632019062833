import { GET_AGENDA, POST_AGENDA, PATCH_AGENDA } from '../types';

const initialState = {
  agenda: {},
  detalles: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AGENDA:
      return {
        ...state,
        agenda: action.payload.agenda,
        detalles: action.payload.detalles,
      };
    case POST_AGENDA:
      return {
        ...state,
        agenda: action.payload.agenda,
        detalles: action.payload.detalles,
      };

    case PATCH_AGENDA:
      return {
        ...state,
        detalles: action.payload.detalles,
      };
    default:
      return state;
  }
};
