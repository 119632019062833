import React, { useState } from 'react';
import { connect } from 'react-redux';

// ant
import { Table } from 'antd';
import CheckIconTable from './../common/CheckIconTable';

const { Column } = Table;

const AgendaTable = (props) => {
  // console.log(props.horarios.length);
  const [pageSize, setPageSize] = useState(20);

  function onShowSizeChange(_, pageSize) {
    setPageSize(pageSize);
  }

  return (
    <Table
      dataSource={props.horarios}
      size="small"
      width="auto"
      height="auto"
      pagination={{
        pageSize: pageSize,
        total: props.horarios.length,
        showSizeChanger: true,
        defaultCurrent: 1,
        onShowSizeChange: onShowSizeChange,
        // showQuickJumper: true,
        // simple: true,
      }}
      scroll={{ x: 1000 }}
      rowKey="Id"
    >
      {/* <Column title="Id" dataIndex="Id" /> */}
      <Column title="Inicio" dataIndex="Hora_Inicio" />
      <Column title="Fin" dataIndex="Hora_Fin" />
      <Column
        title="Lunes"
        align="center"
        width={100}
        render={(text, record) => (
          <CheckIconTable
            day={record.Lunes}
            onClick={() =>
              props.onClickCheckIcon({
                ...record,
                Lunes: !record.Lunes,
              })
            }
          />
        )}
      />
      <Column
        title="Martes"
        align="center"
        width={100}
        render={(text, record) => (
          <CheckIconTable
            day={record.Martes}
            onClick={() =>
              props.onClickCheckIcon({
                ...record,
                Martes: !record.Martes,
              })
            }
          />
        )}
      />
      <Column
        title="Miércoles"
        align="center"
        width={100}
        render={(text, record) => (
          <CheckIconTable
            day={record.Miercoles}
            onClick={() =>
              props.onClickCheckIcon({
                ...record,
                Miercoles: !record.Miercoles,
              })
            }
          />
        )}
      />
      <Column
        title="Jueves"
        align="center"
        width={100}
        render={(text, record) => (
          <CheckIconTable
            day={record.Jueves}
            onClick={() =>
              props.onClickCheckIcon({
                ...record,
                Jueves: !record.Jueves,
              })
            }
          />
        )}
      />
      <Column
        title="Viernes"
        align="center"
        width={100}
        render={(text, record) => (
          <CheckIconTable
            day={record.Viernes}
            onClick={() =>
              props.onClickCheckIcon({
                ...record,
                Viernes: !record.Viernes,
              })
            }
          />
        )}
      />
      <Column
        title="Sábado"
        align="center"
        width={100}
        render={(text, record) => (
          <CheckIconTable
            day={record.Sabado}
            onClick={() =>
              props.onClickCheckIcon({
                ...record,
                Sabado: !record.Sabado,
              })
            }
          />
        )}
      />
      <Column
        title="Domingo"
        align="center"
        width={100}
        render={(text, record) => (
          <CheckIconTable
            day={record.Domingo}
            onClick={() =>
              props.onClickCheckIcon({
                ...record,
                Domingo: !record.Domingo,
              })
            }
          />
        )}
      />
    </Table>
  );
};

AgendaTable.defaultProps = {};

AgendaTable.propTypes = {};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(AgendaTable);
