import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Button } from 'antd';

const ButtonTooltip = ({ icon: Icon, ...props }) => {
  return (
    <Tooltip title={props.title}>
      <Button
        disabled={props.disabled}
        type="link"
        icon={<Icon style={{ color: '#666', fontSize: '20px' }} />}
        onClick={props.onClick}
      />
    </Tooltip>
  );
};

ButtonTooltip.defaultProps = {
  disabled: false,
};

ButtonTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.object,
  disable: PropTypes.bool,
};

export default ButtonTooltip;
