import React, { useState } from 'react';

// ant
import { Card, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

// calendar
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import Toolbar from '../common/calendar/CalendarToolbar';
import { messages } from '../../utils/CalendarMessagesEs';
import 'react-big-calendar/lib/css/react-big-calendar.css';

// components
import ShowCitaModal from './ShowCitaModal';
import CitaFormulario from './CitaFormulario';

// moment
import moment from 'moment';
import 'moment/locale/es';
import CitaBorrar from './CitaBorrar';

moment.locale('es');

const localizer = momentLocalizer(moment);

const CitasCalendario = (props) => {
  const [openCitaForm, setOpenCitaForm] = useState(false);
  const [openModalCita, setOpenModalCita] = useState(false);
  const [openModalBorrar, setOpenModalBorrar] = useState(false);
  const [citaSelected, setCitaSelected] = useState({});
  const [citaEdit, setCitaEdit] = useState(null);
  const [citaBorrar, setCitaBorrar] = useState({});

  const events = [];

  if (props.citas.length) {
    for (let index = 0; index < props.citas.length; index++) {
      const element = props.citas[index];
      // console.log(element);
      const fechaInicio = new Date(element.Fecha_Cita);
      const fechaFinal = new Date(element.Fecha_Cita);
      // console.log(fecha);
      const horaInicio = element.Hora_Inicio.split(':');
      const horaFinal = element.Hora_Fin.split(':');
      // console.log(hora);
      fechaInicio.setHours(horaInicio[0], horaInicio[1], horaInicio[2]);
      fechaFinal.setHours(horaFinal[0], horaFinal[1], horaFinal[2]);

      // console.log(fecha);

      events.push({
        title: element?.estudio?.Nombre || 'Bloqueo',
        start: moment(fechaInicio).toDate(),
        end: moment(fechaFinal).toDate(),
        cita: element,
      });
    }
  }

  // #region Citas
  const editCita = (cita) => {
    console.log(cita);
    setCitaEdit(cita);
    setOpenModalCita(false);
    setOpenCitaForm(true);
  };

  // #endregion
  const onSelectEvent = (e) => {
    // console.log(e);
    setCitaSelected(e);
    setOpenModalCita(true);
  };

  const borrarCita = (cita) => {
    console.log(cita);
    setCitaBorrar(cita);
    setOpenModalCita(false);
    setOpenModalBorrar(true);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let style;

    style = {
      backgroundColor: event.cita.departamento.Color
        ? event.cita.departamento.Color
        : 'blue',
      color: 'white',
    };

    if (event.cita.Tipo === 'Bloqueo') {
      style = { backgroundColor: '#C34A4A', color: 'white' };
    } else if (event.cita.Tipo === 'Extraordinaria') {
      style = { backgroundColor: '#FFC107', color: 'black' };
    }

    return { style };
  };

  const onSelectSlot = ({ start, end }) => {
    props.addCitaWithDate({ start, end });
  };

  return (
    <>
      <Card
        title="Citas"
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={props.addCita}
          >
            Agregar
          </Button>
        }
      >
        <Calendar
          // selectable
          culture="es"
          style={{ minHeight: '30rem' }}
          localizer={localizer}
          views={['month', 'week', 'day']}
          events={events}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={onSelectEvent}
          onSelectSlot={onSelectSlot}
          components={{
            toolbar: (props) => <Toolbar {...props} />,
          }}
          messages={messages}
          eventPropGetter={eventStyleGetter}
          // step={15}
          timeslots={1}
          defaultView={Views.WEEK}
        />
      </Card>
      {/* Ver cita */}
      {openModalCita && (
        <ShowCitaModal
          visible={openModalCita}
          setVisible={setOpenModalCita}
          cita={citaSelected}
          editCita={editCita}
          borrarCita={borrarCita}
        />
      )}

      {openModalBorrar && (
        <CitaBorrar
          cita={citaBorrar}
          visible={openModalBorrar}
          setVisible={setOpenModalBorrar}
        />
      )}

      {/* Citas formulario */}
      {openCitaForm && (
        <CitaFormulario
          visible={openCitaForm}
          setVisible={setOpenCitaForm}
          getCitas={props.getCitas}
          cita={citaEdit}
        />
      )}
    </>
  );
};
export default CitasCalendario;
