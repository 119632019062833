import axiosClient from '../../config/axiosClient';
import { GET_ROLES } from './../types/index';

/**
 * Obtener todos los roles
 */
export const getRoles = () => async (dispatch) => {
  try {
    const response = await axiosClient.get('/roles');
    if (response.data.ok) {
      dispatch({
        type: GET_ROLES,
        payload: { roles: response.data.roles },
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
