import React, { useState, useEffect } from 'react';

// redux
import { connect } from 'react-redux';
import {
  getDepartamentos,
  deleteDepartamento,
  setDepartamento,
} from '../../redux/actions/departamentosAction';

// ant
import { Spin } from 'antd';

// components
import BreadcrumbList from './../common/BreadcrumbList';
import DeptoTable from './DeptoTable';

const DepartamentosIndex = (props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDeptos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDeptos = async () => {
    setLoading(true);
    await Promise.all([
      props.onGetDepartamentos(),
      // props.onGetEstudios('Rayos x'),
    ]);
    setLoading(false);
  };

  const addDepartamento = () => {
    props.onSetDeptartamento(null);
    props.history.push('/departamentos/agregar');
  };

  const editDepartamento = (departamento) => {
    props.onSetDeptartamento(departamento);
    props.history.push('/departamentos/editar');
  };

  const gotoAgenda = (departamento) => {
    props.onSetDeptartamento(departamento);
    props.history.push(`/departamentos/agenda/${departamento.Nombre}`);
  };

  const deleteDepartamento = async (deptoId) => {
    setLoading(true);
    await props.onDeleteDepartamento(deptoId);
    getDeptos();
    setLoading(false);
  };

  return (
    <>
      <BreadcrumbList items={['Departamentos', 'Inicio']} />
      {/* Tabla de departamentos */}
      <Spin spinning={loading}>
        <DeptoTable
          departamentos={props.departamentos}
          addDepartamento={addDepartamento}
          editDepartamento={editDepartamento}
          deleteDepartamento={deleteDepartamento}
          gotoAgenda={gotoAgenda}
        />
      </Spin>
    </>
  );
};

DepartamentosIndex.defaultProps = {};

DepartamentosIndex.propTypes = {};

const mapStateToProps = (state) => {
  return {
    departamentos: state.departamentos.departamentos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetDeptartamento: (departamento) =>
      dispatch(setDepartamento(departamento)),
    onGetDepartamentos: () => dispatch(getDepartamentos()),
    onDeleteDepartamento: (deptoId) => dispatch(deleteDepartamento(deptoId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DepartamentosIndex);
