import React, { useState, useEffect } from 'react';

//  redux
import { connect } from 'react-redux';
import {
  getAgenda,
  postAgenda,
  patchAgenda,
} from '../../redux/actions/agendasAction';

// ant
import { Card, Button, Row, Col, TimePicker, Spin } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';

// components
import BreadcrumbList from '../common/BreadcrumbList';
import AgendaTable from './AgendaTable';

// moment
import moment from 'moment';
import AgendaHeader from './AgendaHeader';

const AgendaIndex = (props) => {
  const [loading, setLoading] = useState(false);
  const [agenda, setAgenda] = useState({
    Hora_Inicio: '',
    Hora_Fin: '',
    Intervalo: '',
    Depto_FK: props.departamento.Id,
  });

  useEffect(() => {
    getAgendaAndDetalles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAgendaAndDetalles = async () => {
    setLoading(true);
    await Promise.all([
      props.onGetAgenda(props.departamento.Id),
      // props.onGetEstudios('Rayos x'),
    ]);

    setLoading(false);
  };

  const onChange = (e, nombre) => {
    if (e) {
      setAgenda({
        ...agenda,
        [nombre]: `${new Date(e._d)
          .getHours()
          .toString()
          .padStart(2, '0')}:${new Date(e._d)
          .getMinutes()
          .toString()
          .padStart(2, '0')}:00`,
      });
    }
  };

  // por horario
  const generarHorarios = async () => {
    setLoading(true);
    // console.log('Generar horarios');
    const inicio = agenda.Hora_Inicio.split(':');
    const final = agenda.Hora_Fin;
    const intervalo = agenda.Intervalo.split(':');

    // sera un arreglo de arreglos
    const horariosLocal = [];

    // for para los dias de la semana
    let count = 0;
    const fecha = new Date();
    fecha.setHours(Number(inicio[0]), Number(inicio[1]), Number(inicio[2]));
    // console.log(moment(inicio, 'hmm').add(1, 'hours'));
    const fechaMoment = moment(fecha);
    // console.log(fechaMoment);
    let horaString = `${fechaMoment
      .hour()
      .toString()
      .padStart(2, '0')}:${fechaMoment
      .minutes()
      .toString()
      .padStart(2, '0')}:00`;

    horariosLocal.push({
      Hora_Inicio: horaString,
      Lunes: true,
      Martes: true,
      Miercoles: true,
      Jueves: true,
      Viernes: true,
      Sabado: true,
      Domingo: true,
      Depto_FK: props.departamento.Id,
    });

    while (horaString !== final) {
      fechaMoment.add(intervalo[0], 'hours');
      fechaMoment.add(intervalo[1], 'minutes');
      horaString = `${fechaMoment
        .hour()
        .toString()
        .padStart(2, '0')}:${fechaMoment
        .minutes()
        .toString()
        .padStart(2, '0')}:00`;
      // poner la hora final del anterior
      horariosLocal[count] = { ...horariosLocal[count], Hora_Fin: horaString };
      count++;
      // console.log(horaString);
      horariosLocal.push({
        Hora_Inicio: horaString,
        Lunes: true,
        Martes: true,
        Miercoles: true,
        Jueves: true,
        Viernes: true,
        Sabado: true,
        Domingo: true,
        Depto_FK: props.departamento.Id,
      });
      if (count >= 100) {
        console.log('se trabo');
        break;
      }
    }

    fechaMoment.add(intervalo[0], 'hours');
    fechaMoment.add(intervalo[1], 'minutes');
    horaString = `${fechaMoment
      .hour()
      .toString()
      .padStart(2, '0')}:${fechaMoment
      .minutes()
      .toString()
      .padStart(2, '0')}:00`;
    // poner la hora final del anterior
    horariosLocal[count] = { ...horariosLocal[count], Hora_Fin: horaString };

    await props.onPostAgenda(props.departamento.Id, agenda, horariosLocal);

    setLoading(false);
  };

  const onClickCheckIcon = async (record) => {
    console.log(record);
    setLoading(true);
    await props.onPatchAgenda(record);
    setLoading(false);
  };

  return (
    <>
      <BreadcrumbList
        items={['Departamentos', `Agenda de ${props.departamento.Nombre}`]}
      />
      <Spin spinning={loading}>
        <Card
          title="Agenda"
          extra={
            <Button
              type="primary"
              icon={<RollbackOutlined />}
              onClick={() => props.history.goBack()}
            >
              Volver
            </Button>
          }
        >
          {/* Header */}
          {props.agenda && <AgendaHeader agenda={props.agenda} />}
          {/* Generar agenda */}
          <Row gutter={[16, 32]}>
            <Col xs={6}>
              <TimePicker
                placeholder="Hora de la primera cita"
                style={{ width: '100%' }}
                format="HH:mm"
                minuteStep={5}
                onChange={(e) => onChange(e, 'Hora_Inicio')}
              />
            </Col>
            <Col xs={6}>
              <TimePicker
                placeholder="Hora de la última cita"
                style={{ width: '100%' }}
                format="HH:mm"
                minuteStep={5}
                onChange={(e) => onChange(e, 'Hora_Fin')}
              />
            </Col>
            <Col xs={6}>
              <TimePicker
                placeholder="Intervalo entre cita"
                style={{ width: '100%' }}
                format="HH:mm"
                minuteStep={5}
                onChange={(e) => onChange(e, 'Intervalo')}
              />
            </Col>
            <Col xs={6}>
              <Button
                disabled={
                  agenda.Hora_Inicio === '' ||
                  agenda.Hora_Fin === '' ||
                  agenda.Intervalo === ''
                }
                type="primary"
                style={{ width: '100%' }}
                onClick={generarHorarios}
              >
                Generar Agenda
              </Button>
            </Col>
          </Row>

          {/* Tabla de horarios */}
          <Row>
            <Col xs={24}>
              <AgendaTable
                horarios={props.detalles || []}
                onClickCheckIcon={onClickCheckIcon}
              />
            </Col>
          </Row>
        </Card>
      </Spin>
    </>
  );
};

AgendaIndex.defaultProps = {};

AgendaIndex.propTypes = {};

const mapStateToProps = (state) => {
  return {
    departamento: state.departamentos.departamentoEdit,
    agenda: state.agenda.agenda,
    detalles: state.agenda.detalles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAgenda: (deptoId) => dispatch(getAgenda(deptoId)),
    onPostAgenda: (deptoId, agenda, detalles) =>
      dispatch(postAgenda(deptoId, agenda, detalles)),
    onPatchAgenda: (detalle) => dispatch(patchAgenda(detalle)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AgendaIndex);
