import React from 'react';

// redux
import { Provider } from 'react-redux';
import store from './redux/store';

// ant
import { Layout } from 'antd';

// router
import AppRouter from './routes/AppRouter';

function App() {
  return (
    <Provider store={store}>
      <Layout>
        <AppRouter />
      </Layout>
    </Provider>
  );
}

export default App;
