import axiosClient from '../../config/axiosClient';
import {
  GET_ESTUDIOS,
  POST_ESTUDIO,
  PUT_ESTUDIO,
  DELETE_ESTUDIO,
  SET_ESTUDIO,
} from './../types/index';

/**
 * Obtener estudios por departamento
 * @param deptoNombre {string}
 */
export const getEstudios = (deptoNombre) => async (dispatch) => {
  try {
    const response = await axiosClient.get(`/estudios/${deptoNombre}`);
    if (response.data.ok) {
      dispatch({
        type: GET_ESTUDIOS,
        payload: {
          estudios: response.data.estudios,
          deptoId: response.data.deptoId,
        },
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Crear un nuevo estudio
 * @param estudio {Object}
 */
export const createEstudio = (estudio) => async (dispatch) => {
  try {
    const response = await axiosClient.post('/estudios', estudio);
    if (response.data.ok) {
      dispatch({
        type: POST_ESTUDIO,
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Editar un estudio
 * @param estudio {Object}
 */
export const updateEstudio = (estudio) => async (dispatch) => {
  try {
    const response = await axiosClient.put('/estudios', estudio);
    if (response.data.ok) {
      dispatch({
        type: PUT_ESTUDIO,
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Borrar un estudio
 * @param estudioId {number}
 */
export const deleteEstudio = (estudioId) => async (dispatch) => {
  try {
    const response = await axiosClient.delete(`/estudios/${estudioId}`);
    if (response.data.ok) {
      dispatch({
        type: DELETE_ESTUDIO,
      });
    }
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Establecer un estudio para editar
 * @param estudio {Object}
 * @param depto {String}
 */
export const setEstudio = (estudio, depto) => async (dispatch) => {
  console.log(depto);
  dispatch({
    type: SET_ESTUDIO,
    payload: { estudio, depto },
  });
};
