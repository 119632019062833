// citas
export const CREATE_CITA = 'CREATE_CITA';
export const GET_CITAS = 'GET_CITAS';
export const GET_ALL_CITAS = 'GET_ALL_CITAS';
export const GET_CITAS_BY_DATE = 'GET_CITAS_BY_DATE';
export const PUT_CITA = 'PUT_CITA';
export const DELETE_CITA = 'DELETE_CITA';

// estudios
export const GET_ESTUDIOS = 'GET_ESTUDIOS';
export const POST_ESTUDIO = 'POST_ESTUDIO';
export const PUT_ESTUDIO = 'PUT_ESTUDIO';
export const DELETE_ESTUDIO = 'DELETE_ESTUDIO';
export const SET_ESTUDIO = 'SET_ESTUDIO';

// departamentos
export const GET_DEPARTAMENTOS = 'GET_DEPARTAMENTOS';
export const POST_DEPARTAMENTO = 'POST_DEPARTAMENTO';
export const PUT_DEPARTAMENTO = 'PUT_DEPARTAMENTO';
export const DELETE_DEPARTAMENTO = 'DELETE_DEPARTAMENTO';
export const SET_DEPARTAMENTO = 'SET_DEPARTAMENTO';

//auth
export const POST_AUTH = 'POST_AUTH';
export const GET_AUTH = 'GET_AUTH';
export const LOGOUT = 'LOGOUT';

// usuarios
export const GET_USUARIOS = 'GET_USUARIOS';
export const POST_USUARIO = 'POST_USUARIO';
export const PUT_USUARIO = 'PUT_USUARIO';
export const PATCH_USUARIO = 'PATCH_USUARIO';
export const DELETE_USUARIO = 'DELETE_USUARIO';
export const SET_USUARIO = 'SET_USUARIO';

// roles
export const GET_ROLES = 'GET_ROLES';

// agenda
export const GET_AGENDA = 'GET_AGENDA';
export const POST_AGENDA = 'POST_AGENDA';
export const PATCH_AGENDA = 'PATCH_AGENDA';

// agenda detalles
export const GET_AGENDA_DETALLES = 'GET_AGENDA_DETALLES';

// cupones
export const GET_CUPONES = 'GET_CUPONES';
export const POST_CUPON = 'POST_CUPON';
export const PUT_CUPON = 'PUT_CUPON';
export const DELETE_CUPON = 'DELETE_CUPON';
export const SET_CUPON = 'SET_CUPON';
