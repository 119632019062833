import React from 'react';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { LogoutOutlined } from '@ant-design/icons';

import { Layout, Menu } from 'antd';
import { logout } from '../../redux/actions/authAction';

const Header = (props) => {
  const history = useHistory();
  const { usuario } = props.auth;

  const logout = () => {
    // props.logout();
    props.logout();
    history.replace('/login');
  };
  return (
    <Layout.Header
      className="site-layout-background"
      style={{ padding: 0, background: '#3DBFE7' }}
    >
      <Menu theme="light" mode="horizontal" style={{ background: '#3DBFE7' }}>
        <Menu.Item style={{ background: 'none', color: '#4a3088' }}>
          <span>
            {usuario.Nombre} / {usuario.departamento.Nombre}
          </span>
        </Menu.Item>

        {/* FIXME que solo se muestre del lado derecho en sm o mayor */}
        <Menu.Item
          style={{ float: 'right', color: '#4a3088' }}
          key="logout"
          icon={<LogoutOutlined />}
          onClick={logout}
        >
          Salir
        </Menu.Item>
      </Menu>
    </Layout.Header>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { logout: () => dispatch(logout()) };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
