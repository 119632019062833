import axiosClient from '../../config/axiosClient';
import {
  GET_CUPONES,
  POST_CUPON,
  SET_CUPON,
  PUT_CUPON,
  DELETE_CUPON,
} from './../types/index';

/**
 * Obtener todos lo cupones
 */
export const getCupones = () => async (dispatch) => {
  try {
    const response = await axiosClient.get('/cupones');
    if (response.data.ok) {
      dispatch({
        type: GET_CUPONES,
        payload: { cupones: response.data.cupones },
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Crear nuevo cupon
 * @param cupon {Object}
 */
export const createCupon = (cupon) => async (dispatch) => {
  try {
    const response = await axiosClient.post('/cupones', cupon);
    if (response.data.ok) {
      dispatch({
        type: POST_CUPON,
      });
    }
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Establecer un cupon para editar
 * @param cupon {Object}
 */
export const setCupon = (cupon) => async (dispatch) => {
  dispatch({
    type: SET_CUPON,
    payload: { cupon },
  });
};

/**
 * Actualizar un cupon
 * @param cupon {Object}
 */
export const updateCupon = (cupon) => async (dispatch) => {
  try {
    const response = await axiosClient.put('/cupones', cupon);
    if (response.data.ok) {
      dispatch({
        type: PUT_CUPON,
      });
    }
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Borrar un cupon
 * @param cuponId {number}
 */
export const deleteCupon = (cuponId) => async (dispatch) => {
  try {
    const response = await axiosClient.delete(`/cupones/${cuponId}`);
    if (response.data.ok) {
      dispatch({
        type: DELETE_CUPON,
      });
    }
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
