import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

const CheckIconTable = (props) => {
  // console.log(props.record);
  return props.day ? (
    <CheckCircleTwoTone style={{ fontSize: '20px' }} onClick={props.onClick} />
  ) : (
    <CloseCircleTwoTone
      style={{ fontSize: '20px' }}
      onClick={props.onClick}
      twoToneColor="#eb2f96"
    />
  );
};

CheckIconTable.defaultProps = {};

CheckIconTable.propTypes = {
  day: PropTypes.bool.isRequired,
};

export default CheckIconTable;
