export const formItemLayout = {
  labelCol: {
    sm: {
      span: 24,
    },
    md: {
      span: 6,
      offset: 2,
    },
  },
  wrapperCol: {
    sm: {
      span: 24,
    },
    md: {
      span: 8,
    },
  },
};

export const formButton = {
  sm: {
    span: 24,
  },
  md: {
    span: 8,
    offset: 8,
  },
};

export const formItemLayoutModal = {
  labelCol: {
    sm: {
      span: 24,
    },
    md: {
      span: 8,
    },
  },
  wrapperCol: {
    sm: {
      span: 24,
    },
    md: {
      span: 16,
    },
  },
};

export const formItemLayoutModalSelect = {
  labelCol: {
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    sm: {
      span: 24,
    },
  },
};

export const formItemLayoutNoLabel = {
  sm: {
    span: 24,
  },
  md: {
    span: 16,
    offset: 8,
  },
};
