import React, { useState } from 'react';

// redux
import { connect } from 'react-redux';
import {
  createDepartamento,
  updateDepartamento,
} from '../../redux/actions/departamentosAction';

// ant
import { Button, Form, Spin, Input, Select, Card, Row, Col } from 'antd';

// Color Picker
import { SwatchesPicker } from 'react-color';

// components
import { formItemLayoutModal } from './../../utils/FormItemLayout';
import { openNotification } from './../../utils/Notification';
import BreadcrumbList from '../common/BreadcrumbList';

const DeptoFormulario = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [color, setColor] = useState(props.departamento?.Color || '');

  const onChange = (color) => {
    // console.log(color);
    setColor(color.hex);
  };

  const handleCancel = () => {
    props.history.goBack();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const valuesForm = await form.validateFields();
      // console.log(valuesForm);
      let response;
      if (props.departamento) {
        // console.log('Editando');
        response = await props.onUpdateDepartamento({
          ...valuesForm,
          Id: props.departamento.Id,
          Color: color,
        });
      } else {
        // console.log('Agregando');
        response = await props.onCreateDepartamento({
          ...valuesForm,
          Color: color,
        });
      }

      if (!response) {
        openNotification(
          'Error',
          'Ocurrio un error, intente mas tarde',
          'error',
        );
      } else if (!response.ok) {
        openNotification('Error', response.message, 'error');
      } else if (response.ok) {
        // await props.getDeptos();
        openNotification(
          'OK',
          `Departamento ${
            props.departamento ? 'editado' : 'agregado'
          } correctamente`,
          'success',
          3,
        );
        handleCancel();
      }
    } catch (error) {
      console.log(error);
      if (error.errorFields?.length) {
        openNotification(
          'Error',
          'Favor de llenar los campos requeridos',
          'error',
        );
      } else {
        openNotification('Error', error.toString(), 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BreadcrumbList
        items={['Departamentos', props.departamento ? 'Editar' : 'Agregar']}
      />
      <Card
        title={
          props.departamento ? 'Editar Departamento' : 'Agregar Departamento'
        }
        extra={[
          <Row gutter={[16, 0]}>
            <Col>
              <Button type="primary" onClick={handleSubmit} loading={loading}>
                {props.departamento ? 'Guardar' : 'Agregar'}
              </Button>
            </Col>
            <Col>
              <Button danger onClick={handleCancel} loading={loading}>
                Cancelar
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Spin spinning={loading}>
          <Form
            {...formItemLayoutModal}
            form={form}
            name="DeptoForm"
            scrollToFirstError
            labelAlign="left"
            initialValues={props.departamento || { Estado: 'Activo' }}
          >
            {/* Nombre */}
            <Form.Item
              name="Nombre"
              label="Nombre"
              rules={[
                {
                  required: true,
                  message: 'El nombre es requerido',
                },
              ]}
            >
              <Input />
            </Form.Item>

            {/* Estado */}
            <Form.Item
              name="Estado"
              label="Estado"
              rules={[
                {
                  required: true,
                  message: 'El estado es requerido',
                },
              ]}
            >
              <Select defaultValue="Activo">
                <Select.Option value="Activo">Activo</Select.Option>
                <Select.Option value="Inactivo">Inactivo</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              // name="Estado"
              label="Color"
            >
              <SwatchesPicker onChange={onChange} width="100%" color={color} />
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </>
  );
};

DeptoFormulario.defaultProps = {};

DeptoFormulario.propTypes = {};

const mapStateToProps = (state) => {
  return {
    departamentos: state.departamentos.departamentos,
    departamento: state.departamentos.departamentoEdit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateDepartamento: (departamento) =>
      dispatch(createDepartamento(departamento)),
    onUpdateDepartamento: (departamento) =>
      dispatch(updateDepartamento(departamento)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DeptoFormulario);
