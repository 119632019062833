import React from 'react';

import { Row, Col } from 'antd';

const AgendaHeader = (props) => {
  return (
    <Row gutter={[16, 32]}>
      <Col xs={6}>
        <h4>Hora de la primera cita: {props.agenda.Hora_Inicio}</h4>
      </Col>
      <Col xs={6}>
        <h4>Hora de la última cita: {props.agenda.Hora_Fin}</h4>
      </Col>
      <Col xs={6}>
        <h4>Intervalo entre cita: {props.agenda.Intervalo}</h4>
      </Col>
    </Row>
  );
};

AgendaHeader.defaultProps = {};

AgendaHeader.propTypes = {};

export default AgendaHeader;
