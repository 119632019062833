import React from 'react';

// react router dom
import { Switch, Redirect, Route } from 'react-router-dom';

// ant
import { Layout } from 'antd';

// rutas
import AdminRoute from './AdminRoute';

/* ===== Paginas ===== */
// citas
import CitasIndex from '../components/citas/CitasIndex';

// departamentos
import DepartamentosIndex from '../components/departamentos/DepartamentosIndex';
import DeptoFormulario from '../components/departamentos/DeptoFormulario';

// agenda
import AgendaIndex from '../components/Agenda/AgendaIndex';

// usuarios
import UsuariosIndex from '../components/usuarios/UsuariosIndex';
import UsuarioFormulario from '../components/usuarios/UsuarioFormulario';

// estudios
import EstudiosIndex from '../components/estudios/EstudiosIndex';
import EstudioFormulario from '../components/estudios/EstudioFormulario';

// cupones
import CuponesIndex from '../components/cupones/CuponesIndex';
import CuponesFormulario from '../components/cupones/CuponesFormulario';

/* ===== Fin Paginas ===== */

// common
import Footer from '../components/common/Footer';
import MenuSider from '../components/common/MenuSider';
import Header from '../components/common/Header';

// ant content
const { Content } = Layout;

const DashboardRoutes = (props) => {
  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <MenuSider />
        <Layout>
          <Header />
          <Content style={{ margin: '1rem' }}>
            <Switch>
              {/* Citas */}
              <Route exact path="/" component={CitasIndex} />

              {/* Departamentos */}
              <AdminRoute
                exact
                path="/departamentos"
                component={DepartamentosIndex}
              />
              <AdminRoute
                exact
                path="/departamentos/:accion"
                component={DeptoFormulario}
              />
              <AdminRoute
                exact
                path="/departamentos/agenda/:Nombre"
                component={AgendaIndex}
              />

              {/* Usuarios */}
              <AdminRoute exact path="/usuarios" component={UsuariosIndex} />
              <AdminRoute
                exact
                path="/usuarios/:accion"
                component={UsuarioFormulario}
              />

              {/* Estudios */}
              <AdminRoute exact path="/estudios" component={EstudiosIndex} />
              <AdminRoute
                exact
                path="/estudios/:accion"
                component={EstudioFormulario}
              />

              {/* Cupones */}
              <AdminRoute exact path="/cupones" component={CuponesIndex} />
              <AdminRoute
                exact
                path="/cupones/:accion"
                component={CuponesFormulario}
              />

              <Redirect to="/" />
            </Switch>
          </Content>
          <Footer />
        </Layout>
      </Layout>
    </>
  );
};

DashboardRoutes.defaultProps = {};

DashboardRoutes.propTypes = {};

export default DashboardRoutes;
