import React, { useRef, useState } from 'react';

// pdf
import ReactToPrint from 'react-to-print';

// ant
import { Button, Descriptions, Row, Col } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';

// utils
import { URL_LOGO_VERTICAL } from '../../utils/Constantes';

// moment.js
import moment from 'moment';

class ComponentToPrint extends React.Component {
  render() {
    const { cita } = this.props;

    return (
      <div
        style={{ marginLeft: '16px', marginRight: '16px', marginTop: '16px' }}
      >
        <Row style={{ marginBottom: '16px' }}>
          <Col span={12}>
            <Row justify="center" align="middle">
              <h1>Consulta</h1>
            </Row>
          </Col>
          <Col span={12}>
            <Row justify="center">
              <img src={URL_LOGO_VERTICAL} alt="..." srcset="" height="64px" />
            </Row>
          </Col>
        </Row>
        <Descriptions
          // title={`Consulta`}
          bordered
          size="small"
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item label="Paciente">
            {`${cita.Nombre} ${cita.Apellido_Paterno} ${cita.Apellido_Materno}`}
          </Descriptions.Item>
          <Descriptions.Item label="Teléfono del paciente">
            {cita.Telefono}
          </Descriptions.Item>
          <Descriptions.Item label="Estudio">
            {cita?.estudio?.Nombre}
          </Descriptions.Item>
          <Descriptions.Item label="Informacion de estudio">
            {cita?.estudio?.Informacion}
          </Descriptions.Item>
          <Descriptions.Item label="Estado de cita">
            {cita.Estado_Cita}
          </Descriptions.Item>
          <Descriptions.Item label="Fecha de cita">
            {moment(cita.Fecha_Cita).format('L')}
          </Descriptions.Item>
          <Descriptions.Item label="Hora Inicio">
            {cita.Hora_Inicio}
          </Descriptions.Item>
          <Descriptions.Item label="Hora Fin">
            {cita.Hora_Fin}
          </Descriptions.Item>
          <Descriptions.Item label="Numero de cita">
            {cita.Id.toString().padStart(3, '0')}
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
}

export default (props) => {
  const [loading, setLoading] = useState(false);
  const componentRef = useRef();
  const { cita } = props;

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <Button
            icon={<PrinterOutlined />}
            disabled={cita.Tipo === 'Bloqueo'}
            style={{ marginRight: '8px' }}
            loading={loading}
          >
            Imprimir
          </Button>
        )}
        content={() => componentRef.current}
        onBeforeGetContent={() => {
          setLoading(true);
        }}
        onAfterPrint={() => setLoading(false)}
      />
      <div style={{ display: 'none' }}>
        <ComponentToPrint ref={componentRef} cita={cita} />
      </div>
    </>
  );
};
